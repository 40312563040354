import React from "react";
import LogoText from "svg/logo-type.svg";
import LogoWithType from "svg/logo-with-type.svg";

const PolicyCard = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="group z-10 h-[12rem] w-[21rem]">
        <div className="relative h-full w-full rounded-xl bg-plum shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
          <div className="absolute inset-0">
            <div className="relative flex h-full flex-col justify-between rounded-lg bg-plum shadow">
              <div className="absolute inset-0 bottom-4 flex p-6">
                <LogoText width="100%" />
              </div>
              <p className="absolute bottom-6 left-6 font-serif font-semibold text-soil">
                Brian Rhea
              </p>
            </div>
          </div>
          <div className="absolute inset-0 h-full w-full rounded-lg bg-evergreen text-center text-white [backface-visibility:hidden] [transform:rotateY(180deg)]">
            <div className="relative flex h-full flex-col justify-between rounded-lg bg-evergreen text-daisy shadow">
              <div className="mx-auto w-4/5 rounded-b-xl bg-plum">
                <p className="py-1 text-xs text-evergreen">
                  Policy Number:{" "}
                  <span className="font-semibold">123456789</span>
                </p>
              </div>
              <div className="p-6">
                <p className="text-xs">
                  This card signifies I have pre-planned a cremation service
                  with After.com. In the event of my passing, please call the
                  number below to claim policy benefits.
                </p>
              </div>
              <div className="grid grid-cols-3 items-center gap-4 px-6 pb-4">
                <p className="whitespace-nowrap text-xs">(804) 905-2639</p>
                <div className="">
                  <LogoWithType className="text-daisy" />
                </div>
                <p className="whitespace-nowrap text-xs">Print documents</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyCard;
