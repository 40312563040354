import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import LoginPasswordInput from "components/Auth/LoginPasswordInput";

const schema = z.object({
  password: z.string().min(1, "Please enter your password"),
});

type PasswordVerificationProps = {
  onSubmit: (password: string) => Promise<void>;
  error?: string | null;
};

export default function PasswordVerification({
  onSubmit,
  error,
}: PasswordVerificationProps) {
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
    },
  });

  const handleSubmit = async (data: { password: string }) => {
    await onSubmit(data.password);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)} className="space-y-6">
        <LoginPasswordInput name="password" control={methods.control} />

        {error && (
          <div className="rounded bg-red-100 p-3 text-sm text-red-700">
            {error}
          </div>
        )}

        <button
          type="submit"
          className="flex w-full items-center justify-center rounded bg-cantelope px-6 py-3 font-bold text-soil transition-all hover:bg-soil hover:text-cantelope disabled:pointer-events-none disabled:opacity-50"
          disabled={methods.formState.isSubmitting}
        >
          {methods.formState.isSubmitting ? "Verifying..." : "Continue"}
        </button>
      </form>
    </FormProvider>
  );
}
