import { ReactNode } from "react";

import LogoWithType from "svg/logo-with-type.svg";

function Footer() {
  return (
    <div className="my-8 space-y-2 text-center sm:my-0">
      <div className="flex justify-center space-x-4 text-sm text-gray-500">
        <a
          href="https://www.after.com/legal/privacy"
          className="inline-block underline decoration-dotted hover:decoration-solid"
          target="_blank"
        >
          Policy
        </a>
        <a
          href="https://www.after.com/legal/terms"
          className="inline-block underline decoration-dotted hover:decoration-solid"
          target="_blank"
        >
          Terms
        </a>
      </div>

      <div className="text-sm text-gray-500">
        Copyright © 2020–{new Date().getFullYear()} · After Services, Inc.
      </div>
    </div>
  );
}

type AuthLayoutProps = {
  title: string;
  description: string;
  children: ReactNode;
};

export default function AuthLayout({
  title,
  description,
  children,
}: AuthLayoutProps) {
  return (
    <>
      <div className="flex min-h-screen flex-col lg:flex-row">
        {/* Left side - Login Form */}
        <div className="flex h-full w-full flex-col justify-center px-6 py-12 lg:h-screen lg:w-2/5 lg:px-8">
          <div className="mx-auto h-full w-full max-w-sm">
            <div className="flex h-full flex-col justify-between">
              <div>
                <div className="relative text-center">
                  <div className="mx-auto mb-8 w-28">
                    <LogoWithType />
                  </div>
                  <h1 className="mb-2 text-3xl font-bold text-soil">{title}</h1>
                  <p className="mb-8 text-base text-gray-600">{description}</p>
                </div>
                {children}
              </div>

              <div className="hidden lg:block">
                <Footer />
              </div>
            </div>
          </div>
        </div>

        {/* Right side - Illustration & Message */}
        <div className="flex w-full items-center justify-center overflow-hidden bg-olive p-0 py-32 sm:py-48 lg:mt-0 lg:w-3/5 lg:px-16 lg:py-12">
          {/* Content Box with Illustrations */}
          <div className="relative w-full px-4 md:mx-auto md:max-w-lg">
            {/* Top Left Flowers */}
            <img
              src="public/bg_flowers_tl.png"
              alt=""
              className="absolute -top-10 left-2 h-48 w-auto scale-150 object-contain md:-top-12"
            />

            {/* Bottom Right Flowers */}
            <img
              src="public/bg_flowers_br.png"
              alt=""
              className="absolute -bottom-24 -right-10 h-48 w-auto -rotate-12 object-contain"
            />

            {/* Content */}
            <div className="relative w-full rounded-xl bg-white p-8 text-center shadow-lg md:p-12">
              <h2 className="mb-2 font-serif text-4xl font-bold text-soil">
                We're here to guide,
              </h2>
              <h2 className="mb-6 text-4xl font-bold text-evergreen">
                not direct
              </h2>
              <p className="text-xl text-soil text-opacity-80">
                Support and guidance, always within reach—whenever you need it.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="block lg:hidden">
        <Footer />
      </div>
    </>
  );
}
