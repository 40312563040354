import { useState, useRef, useCallback, useEffect } from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  ConfirmationResult,
} from "firebase/auth";
import { useFirebase } from "firebase";
import { getFriendlyErrorMessage } from "utils/errors";

export function useSMSVerification() {
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);
  const { auth } = useFirebase();
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const cleanup = () => {
    console.log("Cleaning up");
    try {
      // Clear the reCAPTCHA instance
      if (recaptchaVerifierRef.current) {
        try {
          recaptchaVerifierRef.current.clear();
        } catch (e) {
          console.log("Error clearing reCAPTCHA verifier:", e);
        }
        recaptchaVerifierRef.current = null;
      }

      // Remove our container
      const container = document.getElementById("recaptcha-container");
      if (container) {
        container.remove();
      }

      // Reset global reCAPTCHA state if available
      if (window.grecaptcha && window.grecaptcha.reset) {
        try {
          // Check if there are any reCAPTCHA clients before resetting
          const hasRecaptchaClients =
            document.querySelector(".grecaptcha-badge") ||
            document.querySelector('iframe[src*="recaptcha"]');
          if (hasRecaptchaClients) {
            window.grecaptcha.reset();
          }
        } catch (e) {
          console.log("Error resetting grecaptcha:", e);
        }
      }
    } catch (err) {
      console.log("Error during reCAPTCHA cleanup:", err);
    }
  };

  const startVerification = async (phoneNumber: string) => {
    setIsVerifying(true);
    setError(null);

    try {
      // Format phone number
      const formattedPhone = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+1${phoneNumber.replace(/\D/g, "")}`;

      // Only initialize reCAPTCHA if we don't have a valid instance
      if (!recaptchaVerifierRef.current) {
        console.log("Initializing reCAPTCHA");

        // Check if there's an existing container
        let container = document.getElementById("recaptcha-container");

        // If no container exists, create one
        if (!container) {
          container = document.createElement("div");
          container.id = "recaptcha-container";
          document.body.appendChild(container);
        } else {
          // If container exists but is already used, clear it
          // container.innerHTML = "";
        }

        try {
          recaptchaVerifierRef.current = new RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              callback: () => {
                // reCAPTCHA solved, allow signInWithPhoneNumber
              },
              "expired-callback": () => {
                cleanup();
                setError("Verification timeout. Please try again.");
              },
              "error-callback": (error: Error) => {
                console.log("reCAPTCHA error:", error);
                cleanup();
                setError("reCAPTCHA verification failed. Please try again.");
              },
            },
            auth,
          );
        } catch (error) {
          console.log("Error initializing reCAPTCHA:", error);
          // If we fail to initialize, clean up and try again
          cleanup();
          throw error;
        }
      } else {
        console.log("ReCAPTCHA already initialized");
      }

      const result = await signInWithPhoneNumber(
        auth,
        formattedPhone,
        recaptchaVerifierRef.current,
      );

      setConfirmationResult(result);
      return true;
    } catch (err: any) {
      console.log(err);
      // If there was an error, clean up the instance so we can try again
      cleanup();
      setError(getFriendlyErrorMessage(err.code));
      return false;
    } finally {
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  const verifyCode = async (code: string) => {
    if (!confirmationResult) {
      setError("Verification session expired. Please try again.");
      return false;
    }

    try {
      setError(null);
      await confirmationResult.confirm(code);
      return true;
    } catch (err: any) {
      console.log(err);
      setError(getFriendlyErrorMessage(err.code));
      return false;
    }
  };

  return {
    isVerifying,
    error,
    clearError,
    startVerification,
    verifyCode,
  };
}
