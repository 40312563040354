import React from "react";

export interface BadgeProps {
  status: "green" | "yellow" | "red" | "blue";
  text: string;
  caption?: string;
}

const statusConfig = {
  green: {
    bgColor: "bg-sage",
  },
  yellow: {
    bgColor: "bg-daisy",
  },
  red: {
    bgColor: "bg-[#FF8585]",
  },
  blue: {
    bgColor: "bg-thistle",
  },
};

export const Badge: React.FC<BadgeProps> = ({ status, text, caption }) => {
  const colors = {
    green: "bg-sage",
    yellow: "bg-cantelope",
    red: "bg-red-500",
    blue: "bg-blue-500",
  };

  return (
    <>
      <div className="inline-flex w-fit items-center gap-2.5 rounded-full bg-white px-3 py-1 outline outline-1 outline-oat">
        {status && (
          <div
            className={`h-2 w-2 rounded-full ${colors[status]}`}
            aria-hidden="true"
          />
        )}
        <p className="text-sm font-bold text-soil">{text}</p>
      </div>
      {caption && <p className="text-xs text-soil">{caption}</p>}
    </>
  );
};

export default Badge;
