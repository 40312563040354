import { useNavigate } from "react-router-dom";
import { useVerificationStatus } from "hooks/useVerificationStatus";

export default function AccountVerificationBanner() {
  const navigate = useNavigate();
  const { needsEmailVerification, needsPhoneVerification, email, phoneNumber } =
    useVerificationStatus();

  if (!needsEmailVerification && !needsPhoneVerification) {
    return null;
  }

  return (
    <div className="mb-8 rounded-lg bg-cantelope/10 px-4 py-3 sm:px-6">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          {needsPhoneVerification && (
            <div className="text-soil">
              <p className="font-medium">Verify your phone number</p>
              <p className="text-sm text-soil/75">{phoneNumber}</p>
            </div>
          )}
          {needsEmailVerification && (
            <div className="text-soil">
              <p className="font-medium">Verify your email address</p>
              <p className="text-sm text-soil/75">{email}</p>
            </div>
          )}
        </div>
        <div className="ml-3">
          <button
            onClick={() => {
              if (needsPhoneVerification) {
                navigate("/verify/phone");
              } else {
                navigate("/verify/email");
              }
            }}
            className="whitespace-nowrap rounded bg-cantelope px-3 py-1 text-sm font-medium text-soil transition-colors hover:bg-soil hover:text-cantelope"
          >
            Verify now
          </button>
        </div>
      </div>
    </div>
  );
}
