import React from "react";
import Badge from "../Badge";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
export interface CardProps {
  name: string;
  planId: string;
  link: string;
  status?: "green" | "yellow" | "red" | "blue";
  badgeText: string;
  type?: "plan" | "case";
}

export const Card: React.FC<CardProps> = ({
  name,
  planId,
  link,
  status,
  badgeText,
  type,
}) => {
  const typeDisplay = type === "case" ? `${name}` : `${name}`;

  return (
    <a href={link} className="block">
      <div className="p-4 transition-all duration-100 hover:bg-cantelope-light">
        <div className="flex flex-col items-start justify-between gap-3">
          <div className="flex w-full items-center justify-between">
            <p className="whitespace-nowrap font-serif text-base font-bold text-soil">
              {typeDisplay}
            </p>
            <div className="flex items-center gap-4">
              <Badge status={status} text={badgeText} />
              <div className="flex items-center gap-0">
                <p className="text-sm font-bold text-soil">Details</p>
                <ChevronRightIcon className="h-4 w-4 text-soil" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Card;
