import { useState } from "react";
import { sendSignInLinkToEmail } from "firebase/auth";
import { useFirebase } from "firebase";
import { getFriendlyErrorMessage } from "utils/errors";

export function useEmailLinkAuth() {
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { auth } = useFirebase();

  const sendSignInLink = async (email: string) => {
    if (isSending) return false;

    try {
      setError(null);
      setIsSending(true);

      // Save the email for when user clicks the link
      window.localStorage.setItem("emailForSignIn", email);

      // Get redirect URL from query params or use homepage
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get("redirect") || "/";
      const baseUrl = window.location.origin;

      await sendSignInLinkToEmail(auth, email, {
        url: `${baseUrl}${redirectUrl}`,
        handleCodeInApp: true,
      });

      return true;
    } catch (err: any) {
      console.error("Email link error:", err);
      setError(getFriendlyErrorMessage(err.code));
      return false;
    } finally {
      setIsSending(false);
    }
  };

  return {
    isSending,
    error,
    sendSignInLink,
  };
}
