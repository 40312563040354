import { useEffect, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { PopupModal, InlineWidget } from "react-calendly";
import { usePostHog } from "posthog-js/react";

import { useSafeState } from "hooks/useSafeState";
import { useFacebookPixel } from "components/FacebookPixelProvider";

// ===========================================================
// Calendly Embed Modal
// ===========================================================

type Analytics = {
  facebook?: {
    event: string;
    eventId?: string;
    data?: {
      content_name?: string;
      content_category?: string;
    };
  };
};

export default function CalendlyEmbedModal({
  afterId,
  eventUrl,
  analytics,
  buttonContent,
  onEvent,
  prefillData,
  dataPayload,
  isInline = false,
}: {
  afterId: string;
  eventUrl: string;
  analytics?: Analytics;
  buttonContent: ReactNode;
  onEvent?: (eventType: string) => void;
  prefillData?: Record<string, unknown>;
  dataPayload?: string;
  isInline?: boolean;
}) {
  const { trackEvent } = useFacebookPixel();
  const posthog = usePostHog();
  const [isOpen, setIsOpen] = useSafeState(false);

  // Handle resizing of the Calendly iframe when a time/date is selected and the content
  // gets taller
  useEffect(() => {
    const handleEvent = (event: any) => {
      if (event.origin !== "https://calendly.com") {
        return;
      }

      // set the height of .calendly-inline-widget to the pixels in event.data.payload.height
      const calendlyInlineWidget = document.querySelector(
        ".calendly-inline-widget",
      ) as HTMLElement | null;

      if (calendlyInlineWidget) {
        calendlyInlineWidget.style.height = event.data.payload.height;
      }

      switch (event.data.event) {
        case "calendly.event_type_viewed":
          posthog?.capture(`calendly_call_type_viewed`, { id: afterId });
          Sentry.addBreadcrumb({
            category: "calendly.event_type_viewed",
            message: "Calendly event type viewed",
            level: "info",
          });
          break;

        case "calendly.date_and_time_selected":
          posthog?.capture(`calendly_call_date_and_time_selected`, {
            id: afterId,
          });
          Sentry.addBreadcrumb({
            category: "calendly.date_and_time_selected",
            message: "Calendly date and time selected",
            level: "info",
          });

          break;

        case "calendly.event_scheduled":
          posthog?.capture(`calendly_call_scheduled`, { id: afterId });

          if (analytics?.facebook) {
            const { event, eventId, data } = analytics.facebook;

            trackEvent({
              event,
              eventId,
              data,
            });
          }

          Sentry.addBreadcrumb({
            category: "calendly.event_scheduled",
            message: "Calendly event scheduled",
            level: "info",
          });
          Sentry.setTag("calendly.scheduled_call", "true");
          break;
      }

      if (onEvent) {
        onEvent(event.data.event);
      }
    };

    window.addEventListener("message", handleEvent);

    return () => {
      window.removeEventListener("message", handleEvent);
    };
  }, [afterId, analytics, isInline, onEvent, posthog, setIsOpen, trackEvent]);

  function handleClick() {
    posthog?.capture("cta_button_click", {
      id: "schedule_a_call",
      cta_button_id: "schedule_a_call",
    });
    setIsOpen(true);
  }

  if (isInline) {
    return (
      <div className="h-full min-h-32 w-full">
        <InlineWidget
          url={eventUrl}
          prefill={prefillData}
          pageSettings={{
            hideGdprBanner: true,
          }}
          utm={{
            salesforce_uuid: dataPayload,
          }}
          styles={{}}
        />
      </div>
    );
  }

  return (
    <div>
      <div
        tabIndex={0}
        role="button"
        className="inline-block w-full sm:w-auto"
        onClick={handleClick}
      >
        {buttonContent}
      </div>
      <PopupModal
        url={eventUrl}
        open={isOpen}
        onModalClose={() => setIsOpen(false)}
        rootElement={document.getElementById("root") as HTMLElement}
        prefill={prefillData}
        pageSettings={{
          hideGdprBanner: true,
        }}
        utm={{
          salesforce_uuid: dataPayload,
        }}
      />
    </div>
  );
}
