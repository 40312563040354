import { useFacebookPixel } from "components/FacebookPixelProvider";
import Header from "components/Header";
import { PRE_NEED_LEAD_SCHEDULE_CONFIG } from "constants/onboarding-pre-need";
import { usePostHog } from "posthog-js/react";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function OutOfMarket() {
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const ped = searchParams.get("ped");
  const decoded = ped ? atob(ped) : null;
  const json = decoded ? JSON.parse(decoded) : null;
  const zip = json?.zip;

  const { trackEvent } = useFacebookPixel();

  useEffect(() => {
    if (event_id) {
      trackEvent({
        event: "PreNeedPricingViewAQuote",
        eventId: event_id,
        data: {
          content_name: "pre-need-lead",
          content_category: "pre-need",
          lead_interest: "680b77",
        },
      });
    }
  }, [event_id, trackEvent]);

  return (
    <div>
      <div className="bg-ivory-100 px-2 py-1">
        <Header
          properties={{ call_or_schedule: PRE_NEED_LEAD_SCHEDULE_CONFIG }}
        />
      </div>
      <div className="text-soil">
        <div className="min-h-24 w-full bg-[url('/public/help-bg-bottom.png')] bg-cover bg-left-bottom bg-no-repeat"></div>
        <h1 className="text-center font-serif text-3xl lg:bg-left lg:text-4xl">
          We're so sorry, we don't serve{" "}
          {zip ? <span className="italic">{zip}</span> : <span>your area</span>}{" "}
          at this time.
        </h1>
        <div className="min-h-24 w-full bg-[url('/public/help-bg-top.png')] bg-cover bg-right-top bg-no-repeat"></div>

        <div className="mx-auto max-w-prose p-6 text-sm font-medium md:px-16 md:text-lg lg:p-12 lg:text-xl">
          <p className="mt-4">
            We're continually expanding our markets and will be sure to contact
            you if we serve your area in the future.
          </p>
          <p className="mt-4">
            In the meantime, please enjoy our free Pre-planning guide to create
            a custom end-of-life plan on your own terms.
          </p>
          <div className="my-6 flex justify-center">
            <a
              className="cursor-pointer rounded bg-cantelope px-6 py-2 font-bold"
              href={`https://form.typeform.com/to/QmV0hGIq?utm_source=after_app&utm_campaign=out_of_area&event_id=${event_id}`}
              onClick={() => {
                posthog.capture("marketing_button_click", {
                  id: "out-of-market-pre-planning-guide",
                });
              }}
            >
              Create My Plan
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

