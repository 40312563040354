import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  confirmPasswordReset,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useFirebase } from "firebase";
import { getFriendlyErrorMessage } from "utils/errors";
import AuthLayout from "layouts/AuthLayout";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import LoginPasswordInput from "components/Auth/LoginPasswordInput";

const schema = z.object({
  password: z.string().min(8, "Password must be at least 8 characters"),
});

export default function EmailActionPage() {
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { auth, user } = useFirebase();
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");

  const methods = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    async function handleEmailLink() {
      if (mode === "signIn") {
        if (!isSignInWithEmailLink(auth, window.location.href)) {
          setError("Invalid sign in link.");
          setIsProcessing(false);
          return;
        }

        try {
          const email = window.localStorage.getItem("emailForSignIn");
          if (!email) {
            setError(
              "Could not complete sign in. Please try signing in again.",
            );
            setIsProcessing(false);
            return;
          }

          await signInWithEmailLink(auth, email, window.location.href);
          window.localStorage.removeItem("emailForSignIn");

          const continueUrl = searchParams.get("continueUrl");
          if (continueUrl) {
            const url = new URL(continueUrl);
            navigate(url.pathname + url.search, { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        } catch (err: any) {
          console.error("Email link sign in error:", err);
          setError(getFriendlyErrorMessage(err.code));
          setIsProcessing(false);
        }
      } else if (mode === "resetPassword") {
        setIsProcessing(false);
      } else {
        setError("This link is invalid or has expired.");
        setIsProcessing(false);
      }
    }

    handleEmailLink();
  }, [auth, navigate, searchParams, mode]);

  const handleResetPassword = async ({ password }: { password: string }) => {
    try {
      await confirmPasswordReset(auth, oobCode!, password);

      // If user was already logged in, reauthenticate them with the new password
      if (user) {
        const credential = EmailAuthProvider.credential(user.email!, password);
        await reauthenticateWithCredential(user, credential);

        // Take them back to account page since they were already logged in
        navigate("/account", {
          replace: true,
          state: { message: "Password changed successfully." },
        });
      } else {
        // Not logged in, have them sign in with new password
        navigate("/login", {
          replace: true,
          state: { message: "Password reset successful. Please sign in." },
        });
      }
    } catch (err: any) {
      setError(getFriendlyErrorMessage(err.code));
    }
  };

  if (!oobCode) {
    return (
      <AuthLayout
        title="Invalid Link"
        description="This link is invalid or has expired."
      >
        <button
          onClick={() => navigate("/login")}
          className="w-full rounded bg-cantelope px-6 py-3 font-bold text-soil"
        >
          Return to Sign In
        </button>
      </AuthLayout>
    );
  }

  if (mode === "resetPassword") {
    return (
      <AuthLayout
        title="Reset Password"
        description="Enter your new password below"
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleResetPassword)}
            className="space-y-6"
          >
            <LoginPasswordInput name="password" control={methods.control} />

            {error && (
              <div className="rounded bg-red-100 p-3 text-sm text-red-700">
                {error}
              </div>
            )}

            <button
              type="submit"
              className="flex w-full items-center justify-center rounded bg-cantelope px-6 py-3 font-bold text-soil transition-all hover:bg-soil hover:text-cantelope disabled:pointer-events-none disabled:opacity-50"
              disabled={methods.formState.isSubmitting}
            >
              {methods.formState.isSubmitting
                ? "Resetting..."
                : "Reset Password"}
            </button>
          </form>
        </FormProvider>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      title="Signing you in..."
      description="Please wait while we complete the sign in process."
    >
      <div className="space-y-4 text-center">
        {isProcessing ? (
          <div className="flex items-center justify-center">
            <div className="h-8 w-8 animate-spin rounded-full border-4 border-cantelope border-t-transparent" />
          </div>
        ) : error ? (
          <div className="rounded bg-red-100 p-3 text-sm text-red-700">
            {error}
          </div>
        ) : null}
      </div>
    </AuthLayout>
  );
}
