import React, { useState } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type ShortTextFieldProps = {
  field: FieldConfig;
  control: Control;
};

const ShortTextField: React.FC<ShortTextFieldProps> = ({ field, control }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        required: field.validations?.required
          ? "This field is required."
          : false,
        validate: {
          notOnlyWhitespace: (value) => {
            if (field.validations?.required && value?.trim() === "") {
              return "This field is required.";
            }
            return true;
          },
        },
      }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, error, isDirty, isTouched },
      }) => (
        <TextField isInvalid={invalid} isRequired={field.validations?.required}>
          <FieldLabel text={field.title} />
          <FieldDescription content={field.properties?.description} />
          <Input
            name={name}
            ref={ref}
            value={value || ""}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              onBlur();
              setIsFocused(false);
            }}
            className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
            type="text"
            placeholder={
              field.properties?.placeholder || "Type your answer here"
            }
          />
          {error?.message && isDirty && isTouched && !isFocused && (
            <FieldError>{String(error.message)}</FieldError>
          )}
        </TextField>
      )}
    />
  );
};

export default ShortTextField;
