import LogoText from "svg/logo-type.svg";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { formatPhoneNumber } from "helpers/phone";

interface FooterProps {
  basecamp?: boolean;
}

const global = { phone_pre_need: "+18444150199" }; // TODO: Move to config

export default function Footer({ basecamp = false }: FooterProps) {
  if (basecamp) {
    return (
      <>
        <img
          src="/public/After_Illustration_nature_bg_newcolor_tr.webp"
          alt="Landscape Background"
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <footer className="space-y-20 bg-plum px-4 py-20 sm:px-10">
          <div className="container mx-auto py-8">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
              <div className="space-y-4">
                <h2 className="font-serif text-2xl font-bold text-oat">
                  Company
                </h2>
                <div className="space-y-4">
                  <p className="flex flex-row items-center gap-2 text-base font-bold text-soil">
                    After Services, Inc.
                  </p>
                  <a
                    href="/about"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    About
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/contact-us"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Send a message
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="mailto:support@after.com"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    support@after.com
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                </div>
              </div>
              <div className="space-y-4">
                <h2 className="font-serif text-2xl font-bold text-oat">
                  Immediate Service
                </h2>
                <div className="space-y-4">
                  <a
                    href="/service-areas"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Service Areas
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/pricing"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Pricing
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/faq"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    FAQ
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/experience"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Hospice Care Resources
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/funeral-homes"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Local Funeral Homes
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                </div>
              </div>

              <div className="space-y-4">
                <h2 className="font-serif text-2xl font-bold text-oat">
                  Pre-plan Service
                </h2>
                <div className="space-y-4">
                  <a
                    href="/service-areas"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Service Areas
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/prearrange"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Pricing
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/faq-pre-plan"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    FAQ
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                </div>
              </div>

              <div className="space-y-4">
                <h2 className="font-serif text-2xl font-bold text-oat">
                  See our Resources
                </h2>
                <div className="space-y-4">
                  <a
                    href="/articles"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Articles
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/obituaries"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Obituaries
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/after-life-planning"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Pre-planning Guide
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                  <a
                    href="/affiliate"
                    className="group flex flex-row items-center gap-2 text-base font-bold text-soil"
                  >
                    Affiliate Partner Sign Up
                    <ChevronRightIcon className="h-4 -translate-x-2 scale-50 transform stroke-2 opacity-0 transition-all duration-300 group-hover:translate-x-0 group-hover:scale-100 group-hover:opacity-100" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto flex flex-col items-center justify-center gap-12 rounded-3xl bg-soil p-8 lg:flex-row">
            <h1 className="text-center font-serif text-3xl text-oat sm:text-5xl md:basis-1/3 md:text-left">
              Get in touch with us
            </h1>
            <div className="grid w-full grid-cols-1 gap-12 md:grid-cols-2">
              <a
                href={`tel:${global.phone_pre_need}`}
                className="flex justify-center"
              >
                <button
                  className="block w-full rounded-lg bg-cantelope px-6 py-4 text-center text-base font-bold hover:bg-soil hover:text-cantelope 2xs:max-w-screen-2xs"
                  id="cta_button_call_us_now"
                >
                  {formatPhoneNumber(global.phone_pre_need)}
                </button>
              </a>

              <a
                href="mailto:support@after.com"
                className="block w-full rounded-lg bg-oat px-6 py-4 text-center text-base font-bold hover:bg-soil hover:text-oat 2xs:max-w-screen-2xs"
              >
                support@after.com
              </a>
            </div>
          </div>
          <div className="container mx-auto py-4 text-center">
            <div className="flex flex-wrap items-center justify-center gap-2 text-sm text-soil">
              <div>Copyright © 2020-2025 · After Services, Inc.</div>
              <div>·</div>
              <a href="/legal/terms" className="hover:underline">
                Terms of Service
              </a>
              <div>·</div>
              <a href="/legal/privacy" className="hover:underline">
                Privacy Policy
              </a>
            </div>
          </div>
        </footer>
      </>
    );
  }

  return (
    <footer className="a-footer container mx-auto flex flex-col items-center justify-center pb-8 text-center">
      <hr className="mb-4 w-10/12 sm:w-full" />

      <div className="py-5">
        <LogoText height="1.25rem" />
      </div>

      <small>
        Copyright &copy; {new Date().getFullYear()} · After Services, Inc. ·{" "}
        {__RELEASE__}
      </small>
    </footer>
  );
}
