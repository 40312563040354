import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

import { useFirebase } from "firebase";
import { getFriendlyErrorMessage } from "utils/errors";
import AuthLayout from "layouts/AuthLayout";
import EmailInput from "components/Auth/EmailInput";
import BackButton from "components/Auth/BackButton";

const schema = z.object({
  email: z.string().email("Please enter a valid email address"),
});

export default function ResetPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, user } = useFirebase();
  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);

  // Check if user came from accounts page
  const fromAccounts = location.state?.from === "account";

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: user?.email || "",
    },
  });

  const onSubmit = async (data: { email: string }) => {
    try {
      setError(null);
      await sendPasswordResetEmail(auth, data.email);
      setEmailSent(true);
    } catch (err: any) {
      setError(getFriendlyErrorMessage(err.code));
    }
  };

  const title = user ? "Change Your Password" : "Reset Your Password";

  const description = user
    ? "We'll send you a link to change your password"
    : "Enter your email and we'll send you a reset link";

  if (emailSent) {
    return (
      <AuthLayout
        title="Check Your Email"
        description={`We sent a password ${user ? "change" : "reset"} link to ${methods.getValues("email")}`}
      >
        <div className="text-center">
          <p className="text-sm text-gray-600">
            Click the link in the email to {user ? "change" : "reset"} your
            password.
          </p>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title={title} description={description}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
          <EmailInput
            name="email"
            control={methods.control}
            disabled={!!user}
            description={
              user ? "Your email address" : "We'll send you a reset link"
            }
          />

          {error && (
            <div className="rounded bg-red-100 p-3 text-sm text-red-700">
              {error}
            </div>
          )}

          <button
            type="submit"
            className="flex w-full items-center justify-center rounded bg-cantelope px-6 py-3 font-bold text-soil transition-all hover:bg-soil hover:text-cantelope disabled:pointer-events-none disabled:opacity-50"
            disabled={methods.formState.isSubmitting}
          >
            {methods.formState.isSubmitting
              ? "Sending..."
              : user
                ? "Send Password Change Link"
                : "Send Reset Link"}
          </button>

          {fromAccounts && <BackButton onClick={() => navigate("/account")} />}
        </form>
      </FormProvider>
    </AuthLayout>
  );
}
