import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import OTPInput from "components/Auth/OTPInput";
import { useState, useEffect, useRef } from "react";

const schema = z.object({
  code: z.string().length(6, "Please enter the complete verification code"),
});

type OTPVerificationProps = {
  type: "sms" | "email";
  identifier: string;
  displayLabel?: string;
  onSubmit: (code: string) => Promise<void>;
  onRequestNewCode?: () => Promise<void>;
  error?: string | null;
};

export default function OTPVerification({
  type,
  identifier,
  displayLabel,
  onSubmit,
  onRequestNewCode,
  error,
}: OTPVerificationProps) {
  const [codeComplete, setCodeComplete] = useState(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const otpInputRef = useRef<{ focus: () => void } | null>(null);

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      code: "",
    },
    mode: "onSubmit", // Only validate on submit
  });

  const isSubmitting = methods.formState.isSubmitting;

  // Clear error when component receives new error prop
  useEffect(() => {
    setLocalError(error || null);
  }, [error]);

  // Handle form submission
  const handleSubmit = methods.handleSubmit(async (data) => {
    if (data.code && data.code.length === 6) {
      await onSubmit(data.code);
    }
  });

  // Handle OTP completion
  const handleComplete = (code: string) => {
    setCodeComplete(true);
    if (!isSubmitting) {
      // Set form value and submit
      methods.setValue("code", code);
      handleSubmit();
    }
  };

  // Handle OTP change
  const handleChange = (value: string) => {
    setCodeComplete(value.length === 6);
    // Clear error when user starts typing again
    if (localError) {
      setLocalError(null);
    }
  };

  // Check if the error is a session expiration
  const isSessionExpired =
    localError?.includes("expired") || localError?.includes("SESSION_EXPIRED");

  const handleRequestNewCode = async () => {
    // Clear the form
    methods.reset({ code: "" });
    // Clear errors
    setLocalError(null);
    // Reset code complete state
    setCodeComplete(false);
    // Request new code
    await onRequestNewCode?.();

    // Focus the OTP input after a short delay to ensure the DOM has updated
    setTimeout(() => {
      otpInputRef.current?.focus();
    }, 100);
  };

  console.log({ isSubmitting, codeComplete });

  if (type === "email") {
    return (
      <div className="space-y-6">
        <div className="text-center">
          <p className="text-sm text-gray-600">
            We just sent a sign in link to{" "}
            <span className="font-medium text-soil">
              {displayLabel || identifier}
            </span>
          </p>
          <p className="mt-2 text-sm text-gray-600">
            Click the link in your email to sign in.
          </p>
        </div>

        {error && (
          <div className="rounded bg-red-100 p-3 text-sm text-red-700">
            {error}
          </div>
        )}
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="text-center">
          <p className="text-sm text-gray-600">
            We just sent it to{" "}
            <span className="font-medium text-soil">
              {displayLabel || identifier}
            </span>
          </p>
        </div>

        <div className={isSubmitting ? "opacity-50" : ""}>
          <OTPInput
            name="code"
            control={methods.control}
            length={6}
            onComplete={handleComplete}
            onChange={handleChange}
            disabled={isSubmitting || isSessionExpired}
            error={localError}
            ref={otpInputRef}
          />
        </div>

        {isSessionExpired && onRequestNewCode ? (
          <button
            type="button"
            onClick={handleRequestNewCode}
            className="flex w-full items-center justify-center rounded bg-cantelope px-6 py-3 font-bold text-soil transition-all hover:bg-soil hover:text-cantelope"
          >
            Request a new verification code
          </button>
        ) : (
          <button
            type="submit"
            className="flex w-full items-center justify-center rounded bg-cantelope px-6 py-3 font-bold text-soil transition-all enabled:hover:bg-soil enabled:hover:text-cantelope disabled:opacity-50 disabled:hover:bg-cantelope disabled:hover:text-soil"
            disabled={isSubmitting || !codeComplete}
          >
            {isSubmitting ? "Verifying..." : "Continue"}
          </button>
        )}
      </form>
    </FormProvider>
  );
}
