import React, { useState } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField, Label } from "react-aria-components";

type EmailInputProps = {
  name: string;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  description?: string;
  required?: boolean;
  disabled?: boolean;
};

const EmailInput: React.FC<EmailInputProps> = ({
  name,
  control,
  label = "Email",
  placeholder = "Enter your email",
  description,
  required = false,
  disabled = false,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required ? "Email is required." : false,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Please enter a valid email address.",
        },
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { invalid, error, isDirty, isTouched },
      }) => {
        const [isFocused, setIsFocused] = useState(false);

        return (
          <TextField isInvalid={invalid} isRequired={required}>
            <Label className="text-md -mb-1 block font-semibold text-evergreen">
              {label}
              {required && <span className="ml-1 text-red-500">*</span>}
            </Label>
            {description && (
              <div className="mb-2 text-sm text-gray-600">{description}</div>
            )}
            <Input
              ref={ref}
              type="email"
              value={value || ""}
              onChange={onChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                onBlur();
                setIsFocused(false);
              }}
              className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none disabled:opacity-50"
              placeholder={placeholder}
              autoComplete="email"
              disabled={disabled}
            />
            {error?.message && isDirty && isTouched && !isFocused && (
              <div className="mt-2 text-sm text-red-700">{error.message}</div>
            )}
          </TextField>
        );
      }}
    />
  );
};

export default EmailInput;
