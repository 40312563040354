import { AuthErrorCodes } from "firebase/auth";

export const FIREBASE_ERROR_MESSAGES_MAP: Record<string, string> = {
  [AuthErrorCodes.USER_DELETED]:
    "Could not find account with those login credentials.",
  [AuthErrorCodes.WEAK_PASSWORD]: "Password should be at least 6 characters.",
  [AuthErrorCodes.INVALID_EMAIL]: "Please enter a valid email address.",
  [AuthErrorCodes.EMAIL_EXISTS]: "An account already exists with this email.",
  [AuthErrorCodes.INVALID_PASSWORD]: "Incorrect password. Please try again.",
  [AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER]:
    "Too many attempts. Please try again later.",
  // Add more error mappings as needed
};

export const errorTypeMessage: Record<string, string> = {
  required: "is required.",
  pattern: "is invalid.",
};

export function getFriendlyErrorMessage(errorCode: string): string {
  switch (errorCode) {
    case "auth/invalid-login-credentials":
      return "Invalid email or password. Please try again.";
    case "auth/invalid-phone-number":
      return "The phone number entered is invalid.";
    case "auth/missing-phone-number":
      return "Please enter a phone number.";
    case "auth/invalid-verification-code":
      return "The verification code is incorrect.";
    case "auth/code-expired":
      return "The verification code has expired. Please request a new one.";
    case "auth/too-many-attempts-try-later":
    case "auth/too-many-requests":
      return "Too many verification attempts. Please wait a few minutes before trying again.";
    // Add more cases as needed
    default:
      return "An unexpected error occurred. Please try again.";
  }
}
