import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Controller, Control } from "react-hook-form";

type OTPInputProps = {
  name: string;
  control: Control<any>;
  length?: number;
  onComplete?: (code: string) => void;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: string | null;
};

const OTPInput = forwardRef<{ focus: () => void }, OTPInputProps>(
  (
    {
      name,
      control,
      length = 6,
      onComplete,
      onChange,
      disabled = false,
      error,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    // Expose focus method to parent component
    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRefs.current[0]) {
          inputRefs.current[0].focus();
        }
      },
    }));

    // Keep focus on the last input after submission
    useEffect(() => {
      if (error && inputRefs.current.length > 0) {
        // If there's an error, focus on the first empty input or the last one
        const firstEmptyIndex = inputRefs.current.findIndex(
          (ref) => !ref?.value,
        );
        const indexToFocus =
          firstEmptyIndex >= 0 ? firstEmptyIndex : length - 1;
        inputRefs.current[indexToFocus]?.focus();
      }
    }, [error, length]);

    useEffect(() => {
      // Focus first input on mount
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }, []);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = (e: React.FocusEvent) => {
      // Only set as blurred if focus is not moving to another input in the same component
      const relatedTarget = e.relatedTarget as HTMLElement;
      const isMovingToAnotherInput = inputRefs.current.some(
        (ref) => ref === relatedTarget,
      );

      if (!isMovingToAnotherInput) {
        setIsFocused(false);
      }
    };

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLInputElement>,
      index: number,
    ) => {
      if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    };

    const handleInput = (
      e: React.FormEvent<HTMLInputElement>,
      onFieldChange: (value: string) => void,
      currentValue: string,
      index: number,
    ) => {
      const digit = e.currentTarget.value.slice(-1);
      if (digit && index < length - 1) {
        inputRefs.current[index + 1]?.focus();
      }

      const newValue = currentValue.split("");
      newValue[index] = digit;
      onFieldChange(newValue.join(""));

      // Call the component's onChange prop
      onChange?.(newValue.join(""));
    };

    const handlePaste = (
      e: React.ClipboardEvent<HTMLInputElement>,
      onFieldChange: (value: string) => void,
    ) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text")
        .replace(/\D/g, "")
        .slice(0, length);

      onFieldChange(pastedData);

      // Call the component's onChange prop
      onChange?.(pastedData);

      // If we pasted a complete code, trigger onComplete
      if (pastedData.length === length && onComplete) {
        onComplete(pastedData);
      }

      // Focus the next empty input after the pasted content
      const nextEmptyIndex = Math.min(pastedData.length, length - 1);
      inputRefs.current[nextEmptyIndex]?.focus();
    };

    return (
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value = "" },
          fieldState: { error: fieldError },
        }) => (
          <div className="space-y-4">
            <div className="flex justify-center space-x-4">
              {Array.from({ length }).map((_, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  inputMode="numeric"
                  pattern="\d*"
                  maxLength={1}
                  value={value[index] || ""}
                  onChange={(e) => {
                    handleInput(e, onChange, value, index);
                    // Call onComplete when all digits are filled
                    if (value.length === length - 1 && e.currentTarget.value) {
                      onComplete?.(value + e.currentTarget.value);
                    }
                  }}
                  onPaste={(e) => handlePaste(e, onChange)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={disabled}
                  className="h-12 w-12 rounded-lg border border-gray-300 bg-white text-center text-2xl font-bold text-soil shadow-sm focus:border-cantelope focus:outline-none focus:ring-1 focus:ring-cantelope disabled:bg-gray-100"
                />
              ))}
            </div>

            {/* Error display */}
            {error && (
              <div className="rounded bg-red-100 p-3 text-sm text-red-700">
                {error}
              </div>
            )}

            {/* Validation error - only show when not focused and no parent error */}
            {!error && !isFocused && fieldError && (
              <div className="rounded bg-red-100 p-3 text-sm text-red-700">
                {fieldError.message}
              </div>
            )}
          </div>
        )}
      />
    );
  },
);

export default OTPInput;
