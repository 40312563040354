import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export interface FileCardProps {
  title: string;
  description: string;
  iconSrc: string;
  isUploaded: boolean;
  onUpload: () => void;
  onView: () => void;
  onReplace: () => void;
}

export const FileCard: React.FC<FileCardProps> = ({
  title,
  description,
  iconSrc,
  isUploaded,
  onUpload,
  onView,
  onReplace,
}) => {
  const actionButton = isUploaded ? (
    <div className="flex flex-col items-center gap-1 2xs:flex-row">
      <button
        onClick={onReplace}
        className="text-xs font-bold text-soil hover:text-soil 3xs:max-2xs:order-2"
      >
        Replace
      </button>
      <button
        onClick={onView}
        className="w-full rounded-lg border border-soil bg-soil px-4 py-1 text-center text-sm font-bold text-oat transition-all duration-300 hover:border-cantelope hover:bg-cantelope hover:text-soil 2xs:max-w-screen-2xs sm:text-sm"
      >
        View
      </button>
    </div>
  ) : (
    <button
      onClick={onUpload}
      className="w-full rounded-lg border border-soil px-4 py-1 text-center text-sm font-bold text-soil transition-all duration-300 hover:border-cantelope hover:bg-cantelope hover:text-soil 2xs:max-w-screen-2xs sm:text-sm"
    >
      Upload
    </button>
  );

  return (
    <div className="p-4 transition-all duration-100">
      <div className="flex flex-col items-start justify-between gap-3">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col items-start gap-1">
            <p className="whitespace-nowrap font-serif text-base font-bold text-soil">
              {title}
            </p>
            <p className="text-sm font-normal text-soil/60">{description}</p>
          </div>
          <div className="flex items-center gap-4">{actionButton}</div>
        </div>
      </div>
    </div>
  );
};

export default FileCard;
