import * as Sentry from "@sentry/react";
import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// @ts-ignore
import LoaderCircle from "svg/loader-circle.svg";
// @ts-ignore
import Sparkle from "svg/sparkle.svg";

import { formatPhoneNumber } from "helpers/phone";
import { capitalizeFirstLetter } from "helpers/string";
import { fetchPublicAPI, NetworkError, useGET } from "hooks/usePublicAPI";
import { useSafeState } from "hooks/useSafeState";

import CalendlyEmbedModal from "components/CalendlyEmbedModal";
import { useFacebookPixel } from "components/FacebookPixelProvider";
import Reviews from "components/Reviews";
import StepIndicator from "components/StepIndicator";
import { PLANS, QuoteInfo, PlanId } from "constants/plans";
import {
  OnboardingContainer,
  OnboardingContent,
  OnboardingHeader,
} from "layouts/OnboardingLayout";
import { useQuoteContext } from "./hooks";

const global = { phone_pre_need: "+18444150199" }; // TODO: Move to config

// ===========================================================
// Helpers for saving info for form
// ===========================================================

function formatDOB(dob: string) {
  let [month, date, year] = dob.split("/");

  if (parseInt(month, 10) < 10) {
    month = `0${month}`;
  }

  return [month, date, year].join("/");
}

function formatPaymentPeriod(
  paymentPeriodPolicy: string,
  paymentPeriodOAP: string,
) {
  switch (paymentPeriodPolicy) {
    case "single_pay":
      return "single_pay_with_oap";
    case "3_year_pay":
      if (paymentPeriodOAP === "single_pay") {
        return "3_year_pay_oap_down";
      }
      return "3_year_pay_oap_not_down";
  }
}

function storePolicyInfo(data: Record<string, unknown>, policyId: string) {
  const {
    for_self,
    email,
    phone,
    first_name,
    last_name,
    insured_dob,
    hq1,
    plan,
    payment_period,
    payment_period_oap,
  } = data;
  const payload: any = {
    insured_date_of_birth: formatDOB(insured_dob as string),
    insured_medical_terminal_illness: hq1,
    policy_self_or_other: for_self ? "Myself" : "Loved one",
    insured: {
      first_name: first_name,
      last_name: last_name,
    },
    plan: capitalizeFirstLetter(plan as string),
    payment_period_options_with_oap: formatPaymentPeriod(
      payment_period as string,
      payment_period_oap as string,
    ),
  };

  if (for_self) {
    payload.insured_email = email;
    payload.insured_phone_number = phone;
  } else {
    payload.owner = {
      first_name: first_name,
      last_name: last_name,
    };
    payload.owner_email = email;
    payload.owner_phone_number = phone;
  }

  window.localStorage.setItem(
    `afdp_pre-need-application_${policyId}`,
    JSON.stringify(payload),
  );
}

// ===========================================================
// Helpers for Calendly
// ===========================================================

const readCookie = (name: string) => {
  const nameKey = name + "=";
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((cookieName) =>
    cookieName.trim().startsWith(nameKey),
  );

  if (cookie !== undefined && typeof cookie === "string") {
    return cookie.trim().replace(nameKey, "");
  }

  return null;
};

function getDataPayload(quoteInfo: QuoteInfo) {
  const { event_id, zip } = quoteInfo;

  const payload = {
    zip,
    event_id,
    fbp: readCookie("_fbp"),
    fbc: readCookie("_fbc"),
    user_agent: navigator.userAgent,
    browser_url: window.location.href,
  };
  return btoa(JSON.stringify(payload));
}

// ===========================================================
// Helpers for Quote Display
// ===========================================================

function QuestionsCallUs() {
  const posthog = usePostHog();

  function logEvent() {
    posthog?.capture("link_click", {
      id: "questions_call_us",
      link_id: "questions_call_us",
    });
  }

  return (
    <div className="my-6 text-center">
      <div className="mb-2 font-serif">
        Questions? Talk to a{" "}
        <span className="inline-block">pre-planning expert</span>
      </div>
      <a
        href={`tel:${global.phone_pre_need}`}
        className="underline underline-offset-4"
        onClick={logEvent}
      >
        {formatPhoneNumber(global.phone_pre_need)}
      </a>
    </div>
  );
}

function InvalidClaimUrl() {
  return (
    <div className="py-8 text-center">
      <h2 className="pb-2">Sorry, your URL is invalid.</h2>

      <div className="mx-auto max-w-prose text-base">
        <p className="pt-4">
          Please click the link again or copy/paste it into your browser exactly
          as it appears.
        </p>
        <p className="pt-4">
          Still having issues? We're here to help! Please reach out to our
          support team at{" "}
          <a href="mailto:support@after.com">support@after.com</a> and we'd be
          happy to help.
        </p>
      </div>
    </div>
  );
}

// ===========================================================
// Next Steps
// ===========================================================

interface NextStepsProps {
  quoteInfo: QuoteInfo;
  isHighlighted: boolean;
}

function NextSteps({ quoteInfo, isHighlighted }: NextStepsProps) {
  const quoteContinueEnabled = useFeatureFlagEnabled("pre-need-quote-continue");

  return (
    <div className="flex flex-wrap items-center rounded-xl border-2 border-oat bg-soil px-4 pb-8 pt-6 text-white">
      {quoteContinueEnabled && !isHighlighted && (
        <div className="mb-4 w-full text-center font-serif">
          <h2 className="italic">Or, if you'd prefer</h2>
        </div>
      )}

      {!quoteContinueEnabled && (
        <div className="mb-4 w-full text-center font-serif font-bold">
          <h2 className="text-2xl">Next step</h2>
        </div>
      )}

      <div className="grow text-center sm:flex-1 sm:shrink-0">
        <h3
          className={`mb-2 text-balance font-serif sm:px-0 ${!quoteContinueEnabled && "italic"}`}
        >
          Speak with a licensed pre-planning agent
        </h3>

        <div className="mt-4 flex w-full flex-wrap space-y-4 sm:flex-nowrap sm:space-y-0">
          <div className="w-full">
            <CalendlyEmbedModal
              isInline={true}
              buttonContent={<div>Pick a date and time</div>}
              afterId="pre_need_policy_call"
              eventUrl="https://calendly.com/after-pre-planning/start-pre-planning-portal"
              dataPayload={getDataPayload(quoteInfo)}
              analytics={{
                facebook: {
                  event: "Schedule",
                  eventId: quoteInfo.event_id,
                  data: {
                    content_name: "pre-need-lead",
                    content_category: "pre-need",
                  },
                },
              }}
              prefillData={{
                email: quoteInfo.email,
                name: quoteInfo.full_name,
                smsReminderNumber: quoteInfo.phone || "",
                customAnswers: {
                  a1: quoteInfo.phone || "",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// ===========================================================
// Package Benefits
// ===========================================================

function PackageBenefits({
  benefits,
  isLoading,
}: {
  benefits: Array<Record<string, unknown>>;
  isLoading?: boolean;
}) {
  return (
    <div>
      <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4">
        {benefits.map((benefit: any, index: number) => (
          <li
            key={benefit.label || index}
            className="mt-2 flex flex-col space-y-2 rounded-xl bg-oat p-4 sm:space-y-2"
          >
            {!isLoading && <span className="font-bold">{benefit.label}</span>}
            {isLoading && (
              <div className="animated-background h-5 w-full"></div>
            )}

            {benefit.blurbs &&
              benefit.blurbs.map((blurb: string) => (
                <div className="text-sm" key={blurb}>
                  {blurb}
                </div>
              ))}
          </li>
        ))}
      </ul>
    </div>
  );
}

// ===========================================================
// Quote Information
// ===========================================================

function QuoteInformation({ quoteInfo }: { quoteInfo: QuoteInfo }) {
  return (
    <div
      className="rounded-xl bg-oat px-4 py-5 sm:px-6 sm:py-6"
      data-sentry-mask
    >
      <div className="mb-4 border-b border-b-soil">
        <h2 className="mb-3 font-serif">Your Information</h2>
      </div>

      <div className="flex flex-col space-y-2">
        <div>
          <span className="mr-3 inline-block font-bold">Purchaser:</span>
          {quoteInfo.full_name}
        </div>

        <div>
          <span className="mr-3 inline-block font-bold">Recipient:</span>
          {quoteInfo.for_self ? "Me" : quoteInfo.insured_full_name}
        </div>

        {quoteInfo.hq1 && (
          <div>
            <span className="mr-3 inline-block font-bold">
              Recipient terminal illness:
            </span>
            Yes
          </div>
        )}

        <div>
          <span className="mr-3 inline-block font-bold">
            Recipient Date of Birth:
          </span>
          {quoteInfo.insured_dob}
        </div>

        {/* TODO: This is a temp. check; previous quoteInfo payloads did not have zip */}
        {quoteInfo.zip && (
          <div>
            <span className="mr-3 inline-block font-bold">
              Recipient Zip Code:
            </span>
            {quoteInfo.zip}
          </div>
        )}

        <div>
          <span className="mr-3 inline-block font-bold">Email:</span>
          {quoteInfo.email}
        </div>

        <div>
          <span className="mr-3 inline-block font-bold">Phone:</span>
          {formatPhoneNumber(quoteInfo.phone) || "N/A"}
        </div>
      </div>
    </div>
  );
}

// ===========================================================
// Package Quote
// ===========================================================

function PackageQuotePrice({
  payment_period_oap,
  payment_period,
  oap_total,
  price,
  isLoading,
  isActive,
  onClick,
}: {
  payment_period_oap?: string;
  oap_total?: number;
  payment_period: string;
  price: string;
  isLoading?: boolean;
  isActive?: boolean;
  onClick: (price: any) => void;
}) {
  return (
    <div
      className={`relative flex h-full w-full cursor-pointer items-center justify-center self-end justify-self-center rounded-xl border-2 border-oat bg-oat-50 p-4 transition hover:border-cantelope data-[active=true]:border-cantelope data-[active=true]:bg-cantelope-50 sm:flex-1`}
      data-active={isActive}
      onClick={() => onClick({ price, payment_period_oap, payment_period })}
    >
      <div className="text-center">
        {payment_period_oap === "single_pay" && oap_total && oap_total > 0 && (
          <>
            <div className="absolute -right-2 -top-2 z-20 h-5 w-5 rounded-full bg-cantelope p-1 sm:-right-0">
              <Sparkle className="fill-none stroke-white" />
            </div>
            <div className="italic">${oap_total} down payment</div>
          </>
        )}

        {isLoading && (
          <div className="animated-background mb-2 h-8 text-2xl"></div>
        )}
        {!isLoading && (
          <div className="mb-2 text-2xl">{price.replace(".00", "")}</div>
        )}

        <div>
          {payment_period === "single_pay"
            ? "Pay total"
            : "Pay monthly for 3 years"}
        </div>
      </div>
    </div>
  );
}

type TimeOutParams = {
  timeLimit?: number;
  reportTimeOut?: boolean;
  timeOutId?: string;
};

function useTimeOut({
  timeLimit = 30000,
  timeOutId,
  reportTimeOut,
}: TimeOutParams = {}) {
  const posthog = usePostHog();
  const [hasTimedOut, setHasTimedOut] = useSafeState(false);
  const [timeoutCancelled, setTimeoutCancelled] = useSafeState(false);
  const timeoutRef = useRef<number>(0);

  useEffect(() => {
    if (!timeoutCancelled) {
      timeoutRef.current = window.setTimeout(
        () => {
          setHasTimedOut(true);

          if (reportTimeOut) {
            posthog?.capture("time_out_reached", {
              time_out_id: timeOutId,
            });
            Sentry.addBreadcrumb({
              category: "timeout.reached",
              message: ["Time out reached", timeOutId]
                .filter((s) => !s)
                .join(": "),
              level: "warning",
            });
          }
        },
        timeLimit,
        timeOutId,
        reportTimeOut,
      );
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [
    timeLimit,
    reportTimeOut,
    timeOutId,
    posthog,
    setHasTimedOut,
    timeoutCancelled,
  ]);

  function cancelTimeout() {
    clearTimeout(timeoutRef.current);
    setHasTimedOut(false);
    setTimeoutCancelled(true);
  }

  return [hasTimedOut, timeoutRef, cancelTimeout] as const;
}

function ActionButton({
  disabled,
  id,
  defaultText,
  loadingText = "Loading...",
  onClick,
}: {
  disabled?: boolean;
  id?: string;
  defaultText: string;
  loadingText?: string;
  onClick: () => void;
}) {
  const [loading, setLoading] = useSafeState(false);

  function handleClick() {
    setLoading(true);
    onClick();
  }

  return (
    <button
      className="flex w-full items-center justify-center rounded-xl bg-cantelope px-8 py-3 font-bold text-soil outline outline-2 outline-cantelope transition-all enabled:hover:bg-soil enabled:hover:text-cantelope disabled:cursor-default data-[loading='true']:cursor-default data-[loading='true']:opacity-90 data-[loading='false']:disabled:opacity-50 sm:w-auto"
      disabled={disabled || loading}
      data-loading={loading}
      id={id || "action_button"}
      onClick={handleClick}
    >
      {loading && (
        <>
          <LoaderCircle className="mr-2 h-5 w-5 animate-spin" />
          {loadingText}
        </>
      )}
      {!loading && defaultText}
    </button>
  );
}

type PaymentOption = {
  payment_period_oap: string | null;
  payment_period: string;
};

type PackageQuoteClickPayload = {
  plan: string;
  payment_period: string;
  payment_period_oap: string | null;
};

type PackageQuoteProps = {
  name: string;
  plan: any;
  quote: any;
  quoteInfo: QuoteInfo;
  onClick: (payload: PackageQuoteClickPayload) => Promise<void>;
  add_ons: any[];
};

function PackageQuote({
  name,
  plan,
  quote,
  quoteInfo,
  onClick,
  add_ons = [],
}: PackageQuoteProps) {
  const quoteContinueEnabled = useFeatureFlagEnabled("pre-need-quote-continue");
  const [hasTimedOut, timeoutRef, cancelTimeout] = useTimeOut({
    reportTimeOut: true,
    timeOutId: "package_quote",
  });
  const [activePrice, setActivePrice] = useSafeState<string>();
  const [paymentOption, setPaymentOption] = useSafeState<
    PaymentOption | undefined
  >();

  useEffect(() => {
    if (plan && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      // Below lint warning is resolved in future react release
      // eslint-disable-next-line react-compiler/react-compiler
      timeoutRef.current = 0;
    }

    // Clear the active price when the plan changes
    setActivePrice(undefined);
    setPaymentOption(undefined);
  }, [plan, timeoutRef, setActivePrice, setPaymentOption]);

  useEffect(() => {
    if (quote && timeoutRef.current) {
      cancelTimeout();
    }
  }, [quote, cancelTimeout, timeoutRef]);

  const loadingOptions = [
    { payment_period: "single_pay", name: "a" },
    { payment_period: "3_year_pay", name: "b" },
    { payment_period: "3_year_pay", name: "c" },
  ];

  const quoteOptions = quote?.options || loadingOptions;

  function handlePriceClick(payload: any) {
    if (quoteContinueEnabled) {
      setActivePrice(payload.price);
      setPaymentOption({
        payment_period_oap: payload.payment_period_oap,
        payment_period: payload.payment_period,
      });
    } else {
      setActivePrice(payload.price);
      handleContinue({ plan: plan && plan.slug, paymentOption });
    }
  }

  async function handleContinue(payload: Record<string, unknown>) {
    const paymentOption = payload.paymentOption as PaymentOption;
    await onClick({
      plan: payload.plan as string,
      ...paymentOption,
    });
  }

  return (
    <div className="w-full rounded-b-xl border-b-2 border-l-2 border-r-2 border-oat p-4 sm:p-6">
      {quoteContinueEnabled && (
        <header className="flex flex-wrap items-start justify-between">
          <div className="my-3 flex items-baseline">
            <StepIndicator stepNumber={2} stepText="Select payment option" />
          </div>
        </header>
      )}

      <div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
          {!hasTimedOut &&
            quoteOptions
              .filter((option: any) => {
                // If insured answer yes to health question 1, multi-pay options are unavailable
                if (quoteInfo.hq1) {
                  return option.payment_period !== "3_year_pay";
                }
                return true;
              })
              .map((option: any) => (
                <PackageQuotePrice
                  price={option.price}
                  oap_total={plan?.oap_total}
                  payment_period_oap={option.payment_period_oap}
                  payment_period={option.payment_period}
                  onClick={handlePriceClick}
                  isActive={option.price && option.price === activePrice}
                  isLoading={!quote}
                  key={option.name}
                />
              ))}

          {!quote && hasTimedOut && (
            <div className="flex h-full w-full items-center justify-center self-end justify-self-center rounded-xl border-2 border-soil p-4 text-center sm:col-span-2">
              <strong>Your quote has been sent to your email!</strong>
            </div>
          )}
        </div>

        {add_ons
          .filter((add_on) => add_on.product_type === "Fee")
          .map((add_on) => {
            return (
              <div
                key={`${add_on.name}-${add_on.price}`}
                className="-mb-1 mt-2 text-sm italic"
              >
                Includes {add_on.name} of ${add_on.price}
              </div>
            );
          })}

        {name === "Base" && (
          <div className="-mb-1 mt-2 text-sm italic">
            Does not include price-lock guarantee
          </div>
        )}
      </div>

      {quoteContinueEnabled && (
        <div className="mt-5">
          <div className="mb-3 flex items-baseline">
            <StepIndicator stepNumber={3} stepText="Confirm your information" />
          </div>

          <ActionButton
            id="cta_button_continue_online"
            disabled={!paymentOption}
            onClick={() =>
              handleContinue({ plan: plan && plan.slug, paymentOption })
            }
            defaultText="Continue"
            loadingText="Saving details..."
          />
        </div>
      )}
    </div>
  );
}

const PLAN_OPTIONS = [
  {
    name: "Base",
    id: "simple",
  },
  {
    name: "Crest",
    id: "value",
  },
  {
    name: "Summit",
    id: "choice",
  },
];

type MainPackageQuoteProps = {
  quotes: Array<Record<string, unknown>>;
  add_ons: Array<Record<string, unknown>>;
  quoteInfo: QuoteInfo;
  plans: Array<Record<string, unknown>>;
  featureFlag: string;
};

function MainPackageQuote({
  quotes,
  add_ons,
  quoteInfo,
  plans,
  featureFlag,
}: MainPackageQuoteProps) {
  const quoteContinueEnabled = useFeatureFlagEnabled("pre-need-quote-continue");
  const posthog = usePostHog();
  const { trackEvent } = useFacebookPixel();
  const navigate = useNavigate();
  // TODO: When the user switches plans, store in session storage and restore on reload
  const [currentPlan, setCurrentPlan] = useSafeState(quoteInfo.plan);
  const [highlightNextSteps, setHighlight] = useSafeState(false);
  const nextStepsRef = useRef<HTMLDivElement>(null);
  const name = PLANS[currentPlan];
  const plan = plans && plans.find((p: any) => name === p.name);
  const quote =
    quotes && quotes.find((q: any) => q.name === PLANS[currentPlan]);
  const [continueError, setContinueError] = useSafeState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (highlightNextSteps) {
        setHighlight(false);
      }
    };

    if (highlightNextSteps && nextStepsRef.current) {
      nextStepsRef.current.scrollIntoView();
    }

    window.setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 750);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [highlightNextSteps, setHighlight]);

  function handleClick(planId: PlanId) {
    posthog?.capture("plan_toggle_click", {
      plan_prev: currentPlan,
      plan_next: planId,
    });
    setCurrentPlan(planId);
  }

  async function handleContinue(payload: {
    plan: string;
    payment_period: string;
    payment_period_oap: string | null;
  }) {
    window.localStorage.setItem(
      `policy_onboarding:plan_payment:${quoteInfo.event_id}`,
      JSON.stringify(payload),
    );

    if (!quoteContinueEnabled) {
      setHighlight(true);
      return;
    }

    posthog?.capture("pre_need_policy_quote_select_plan_payment", {
      ...payload,
    });

    try {
      const response = await fetchPublicAPI(
        `/quotes/policy/${quoteInfo.event_id}/continue`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.policy_id) {
        storePolicyInfo(
          {
            ...quoteInfo,
            ...payload,
          },
          response.policy_id,
        );

        navigate(`/forms/pre-need-application/${response.policy_id}`, {
          state: {
            form_context: {
              from: "quote-continue",
            },
          },
        });
      } else {
        setContinueError(true);
        setHighlight(true);
      }
    } catch (error) {
      Sentry.captureException(error);

      if (error instanceof NetworkError) {
        console.error(
          "Error saving form progress:",
          error.statusCode,
          error.responseData,
        );
        setContinueError(true);
      } else {
        console.error("Error saving form progress:", error);
        setContinueError(true);
      }

      setHighlight(true);
    }
  }

  // Handle tracking events
  useEffect(() => {
    if (quoteInfo && quoteInfo.event_id) {
      trackEvent({
        event: "PreNeedPricingViewAQuote",
        eventId: quoteInfo.event_id,
        data: {
          content_name: "pre-need-lead",
          content_category: "pre-need",
          package: quoteInfo.plan,
          lead_interest: "03e634",
        },
      });
    }
  }, [quoteInfo, trackEvent]);

  return (
    <div>
      {quoteContinueEnabled && (
        <div className="mb-3">
          <StepIndicator stepNumber={1} stepText="Select plan" />
        </div>
      )}

      <div className="space-y-4 sm:space-y-6">
        <div className="-mb-4">
          <div className="flex w-full">
            {PLAN_OPTIONS.map((p: any) => (
              <button
                onClick={() => handleClick(p.id)}
                className={`group relative mt-[-2px] flex grow basis-1/3 justify-center rounded-none border-oat py-3 ${p.id === currentPlan ? "rounded-t-xl border-x-2 border-b-0 border-t-2 border-oat bg-white" : "border-b-2 border-t-2 border-t-white text-evergreen/80"} `}
                key={p.name}
                data-active={p.id === currentPlan}
                data-plan={p.id}
              >
                <div className="flex items-baseline">
                  <div className="text-xl font-bold">{p.name}</div>
                  <div className="ml-1 hidden font-serif font-bold italic sm:block">
                    package
                  </div>
                </div>
              </button>
            ))}
          </div>

          {continueError && (
            <div className="flex flex-col items-center justify-center rounded-b-xl border-b-2 border-l-2 border-r-2 border-oat p-10">
              <h2 className="mb-2 font-serif">Thank you!</h2>
              <div>
                Please schedule a call to proceed with your pre-planning
                arrangements.
              </div>
            </div>
          )}
          {!continueError && (
            <PackageQuote
              name={name}
              plan={plan}
              quote={quote}
              quoteInfo={quoteInfo}
              onClick={handleContinue}
              add_ons={add_ons}
            />
          )}
        </div>

        <div className="pt-4" ref={nextStepsRef}>
          <div
            className="rounded-xl transition data-[highlight=true]:shadow-lg data-[highlight=true]:shadow-cantelope"
            data-highlight={highlightNextSteps}
          >
            {featureFlag === "control" && (
              <NextSteps
                quoteInfo={quoteInfo}
                isHighlighted={highlightNextSteps}
              />
            )}
            {featureFlag === "bg-soil" && (
              <NextSteps
                quoteInfo={quoteInfo}
                isHighlighted={highlightNextSteps}
              />
            )}
            {featureFlag === "calendly-visible" && (
              <NextSteps
                quoteInfo={quoteInfo}
                isHighlighted={highlightNextSteps}
              />
            )}
          </div>
        </div>

        <MainPackageBenefits
          quoteInfo={quoteInfo}
          currentPlan={currentPlan}
          plans={plans}
        />
      </div>
      <div className="mt-6">
        <h2 className="mb-1 font-serif">Personal Touches</h2>
        <p>
          These thoughtful options help create a lasting tribute in a way that
          feels right for you. Speak with one of our licensed, US-based
          pre-planning agents for more information.
        </p>
        <div className="mt-2">
          <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-4">
            <li className="mt-2 flex flex-col space-y-2 rounded-xl bg-oat-50 p-4 sm:space-y-2">
              <span className="font-bold">Solidified Remains</span>
              <div className="text-sm">
                Solidified remains act and feel like smooth river stones. The
                material is clean and permanent like ceramic. Now, everyone in
                the family can comfortably hold, scatter, keep, carry, share,
                and remember in a way that is special to them.
              </div>
            </li>
            <li className="mt-2 flex flex-col space-y-2 rounded-xl bg-oat-50 p-4 sm:space-y-2">
              <span className="font-bold">Keepsake Package</span>
              <div className="text-sm">
                A curated selection of keepsakes from our memorial store. Loved
                ones will have the choice of a tasteful brass songbird in
                various colors, meaningful pendants, and living glass decorative
                pieces.
              </div>
            </li>
            <li className="mt-2 flex flex-col space-y-2 rounded-xl bg-oat-50 p-4 sm:space-y-2">
              <span className="font-bold">Memorial Trees</span>
              <div className="text-sm">
                Planting a tree in memory of someone is not only a touching
                tribute but also a gift to future generations, embodying a
                living legacy in our National Forests. These memorial trees
                stand as noble monuments, playing a vital role in the ecological
                balance for many years.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function MainPackageBenefits({ plans, currentPlan }: any) {
  const name = PLANS[currentPlan];
  const plan = plans && plans.find((p: any) => name === p.name);

  return (
    <div className="mt-2">
      <h2 className="mb-1 font-serif">
        {plan?.whats_included_text
          ? `Benefits – ${plan.whats_included_text}`
          : `Benefits Include`}
      </h2>

      {
        <PackageBenefits
          benefits={plan ? plan.features : [{}, {}, {}, {}, {}, {}]}
          isLoading={!plan}
        />
      }
    </div>
  );
}

// ===========================================================
// Quote Options
// ===========================================================

export default function QuoteOptions() {
  const { quote, quoteInfo } = useQuoteContext() as any;
  const { trackEvent } = useFacebookPixel();
  const posthog = usePostHog();
  const { data: plans } = useGET("/pre-need/plans");
  const [featureFlag, setFeatureFlag] = useState<string>("control");
  const [posthogReady, setPosthogReady] = useState<boolean>(false);

  // Handle PostHog initialization and feature flag loading
  useEffect(() => {
    // Wait for PostHog to be ready
    if (!posthog) return;

    const loadFeatureFlag = () => {
      try {
        const flagValue = posthog.getFeatureFlag(
          "increase-scheduling-on-quote-page",
        );

        // Only set non-null values
        if (flagValue !== null && flagValue !== undefined) {
          setFeatureFlag(String(flagValue));
        } else {
          setFeatureFlag("control");
        }
      } catch (error) {
        Sentry.captureException(error);
        setFeatureFlag("control");
      }
    };

    // Set up feature flag listener
    posthog.onFeatureFlags(() => {
      setPosthogReady(true);
      loadFeatureFlag();
    });

    // Initial load attempt
    if (posthog.isFeatureEnabled("increase-scheduling-on-quote-page")) {
      loadFeatureFlag();
    }
  }, [posthog]);

  // Handle tracking events
  useEffect(() => {
    if (quoteInfo && quoteInfo.event_id) {
      trackEvent({
        event: "PreNeedPricingViewAQuote",
        eventId: quoteInfo.event_id,
        data: {
          content_name: "pre-need-lead",
          content_category: "pre-need",
          package: quoteInfo.plan,
          lead_interest: "03e634",
        },
      });
    }
  }, [quoteInfo, trackEvent]);

  // Log feature flag state changes
  useEffect(() => {
    console.log("[PostHog] Current feature flag state:", {
      value: featureFlag,
      posthogReady,
    });
  }, [featureFlag, posthogReady]);

  if (quoteInfo && !quoteInfo?.event_id) {
    return <InvalidClaimUrl />;
  }

  return (
    <OnboardingContainer>
      <OnboardingHeader className="sm:mt-8 sm:text-3xl">
        Hi {quoteInfo.first_name}, here's{" "}
        <span className="inline-block">your quote</span>
      </OnboardingHeader>

      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-3 lg:gap-2">
        <OnboardingContent className="grid w-full auto-rows-min grid-cols-1 gap-6 sm:col-span-2 sm:gap-6">
          <MainPackageQuote
            plans={plans?.plans}
            quotes={quote?.quotes}
            add_ons={quote?.add_ons}
            quoteInfo={quoteInfo}
            featureFlag={featureFlag}
          />
        </OnboardingContent>

        <div className="mx-auto mt-6 px-3 sm:px-4 md:mt-4 lg:mt-0">
          <OnboardingContent className="w-full p-0 sm:p-0">
            <QuoteInformation quoteInfo={quoteInfo} />

            <QuestionsCallUs />
          </OnboardingContent>

          <OnboardingContent className="w-full p-0 sm:p-0">
            <div className="overflow-hidden rounded-xl border-2 border-oat">
              <div className="px-4 sm:px-6">
                <div className="mb-4 mt-5 border-b border-b-soil sm:mt-6">
                  <h2 className="mb-3 font-serif">Hear From Our Families</h2>
                </div>
              </div>

              <div className="px-4 pb-4 sm:px-6 sm:pb-6">
                <Reviews max={3} />
              </div>
            </div>
          </OnboardingContent>
        </div>
      </div>
    </OnboardingContainer>
  );
}
