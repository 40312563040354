import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useFirebase } from "firebase";
import { useEffect, useState } from "react";
import {
  BasecampContainer,
  BasecampContent,
} from "layouts/OnboardingLayout/OnboardingLayout";
import Header from "components/Header";
import VerificationStatus from "components/Account/VerificationStatus";
import Footer from "components/Footer";
import ProgressStepper, { ProgressStep } from "components/ProgressStepper";
import Card from "components/Card";
import FileCard from "components/FileCard";
import { demoStates, AccountState } from "./demoStates";
import Badge from "components/Badge";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import PolicyCard from "components/PolicyCard/PolicyCard";
import { formatPhoneNumber } from "helpers/phone";
const global = { phone_pre_need: "+18444150199" }; // TODO: Move to config
const DEMO_PASSWORD = "after-basecamp-demo";

// UI Components
const SuccessMessage = ({ message }: { message: string }) => (
  <div className="rounded bg-green-100 p-3 text-sm text-green-700">
    {message}
  </div>
);

const SectionHeader = ({
  head,
  subhead = "",
}: {
  head: string;
  subhead?: string;
}) => (
  <div>
    <h2 className="border-b border-oat p-4 text-lg font-semibold text-evergreen">
      {head}
    </h2>
    {subhead && (
      <h3 className="text-sm font-normal text-evergreen/60">{subhead}</h3>
    )}
  </div>
);

const PlansSection = ({
  plans,
  showDetails,
}: {
  plans: AccountState["plans"];
  showDetails: boolean;
}) => {
  if (!plans.length) return null;

  return (
    <>
      {showDetails && (
        <div className="mx-auto max-w-screen-xs space-y-12">
          <div className="flex justify-center">
            <PolicyCard />
          </div>
          <div className="mx-auto flex flex-col gap-2 sm:flex-row sm:items-start sm:justify-between">
            <div className="flex flex-col gap-1 sm:w-1/3">
              <div className="text-base font-medium text-evergreen">
                Policy Holder
              </div>
              <div className="text-lg font-bold text-soil">{plans[0].name}</div>
            </div>
            <div className="flex flex-col gap-1 sm:w-1/3">
              <div className="text-base font-medium text-evergreen">
                Policy #
              </div>
              <div className="text-lg font-bold text-soil">
                {plans[0].planId}
              </div>
            </div>
            <div className="flex flex-col gap-2 sm:w-1/3">
              <div className="text-base font-medium text-evergreen">Status</div>
              <Badge
                status={plans[0].status}
                text={plans[0].badgeText}
                caption={plans[0].badgeCaption}
              />
            </div>
          </div>
          <div className="mx-auto flex max-w-screen-xs justify-center">
            <button className="w-full rounded-lg bg-cantelope px-10 py-4 text-sm font-bold text-soil hover:bg-soil hover:text-cantelope sm:inline-block sm:w-auto">
              Download policy documents
            </button>
          </div>
          <div className="space-y-4">
            <div className="text-base text-soil">Your plan includes:</div>
            <ul className="flex list-none flex-col space-y-2 text-base">
              <li className="flex items-center font-bold">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Honorable cremation
              </li>
              <li className="flex items-center font-bold">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Permits and filing documents
              </li>
              <li className="flex items-center font-bold">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Transportation of loved one (30 miles)
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Dedicated care specialist
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Temporary scattering urn (on request)
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Secure Refrigeration (5 Business Days)
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Cremation container (under 200 lbs)
              </li>
              <li className="flex items-center">
                <CheckCircleIcon className="mr-2 h-5 w-5 text-evergreen" />
                Online obituary listing
              </li>
            </ul>
          </div>
          <div className="mx-auto max-w-screen-xs">
            <div className="flex flex-col divide-y divide-oat">
              <FilesSection plans={plans} />
            </div>
          </div>
        </div>
      )}

      {!showDetails && (
        <div className="rounded-lg border border-oat">
          <SectionHeader head="Your policies" />
          <div className="flex flex-col divide-y divide-oat">
            {plans.map((plan) => (
              <Card key={plan.planId} {...plan} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const CasesSection = ({
  cases,
  showDetails,
}: {
  cases: AccountState["cases"];
  showDetails: boolean;
}) => {
  if (!cases.length) return null;

  const getStepDescription = (step: ProgressStep) =>
    step.state === "inactive" ? (
      <>
        <p className="text-base text-soil">
          We'll guide you through this when the time comes.
        </p>
      </>
    ) : (
      step.description
    );

  const headerTitle = showDetails
    ? `${cases[0].name} is in our care`
    : "Your arrangements";

  return (
    <>
      {showDetails && (
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <h2 className="text-lg font-semibold text-evergreen">
              {headerTitle}
            </h2>
            <h3 className="text-base font-normal text-evergreen/80">
              Here are the steps we're taking to ensure {cases[0].name} is cared
              for.
            </h3>
          </div>
          <div className="flex flex-col">
            {cases.map((caseItem) => (
              <div key={caseItem.planId}>
                <div className="flex flex-col gap-4">
                  {!showDetails && <Card {...caseItem} />}
                  {showDetails && (
                    <ProgressStepper
                      steps={caseItem.steps.map((step) => ({
                        ...step,
                        description: getStepDescription(step),
                      }))}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="container mx-auto flex flex-col items-center justify-center gap-6 rounded-3xl bg-soil p-8 lg:flex-row">
            <h2 className="text-center font-serif text-3xl text-oat sm:text-5xl md:basis-1/3 md:text-left">
              We're here for you
            </h2>
            <div className="grid w-full grid-cols-1 gap-6 md:grid-cols-2">
              <a
                href={`tel:${global.phone_pre_need}`}
                className="flex justify-center"
              >
                <button
                  className="block w-full rounded-lg bg-cantelope px-6 py-4 text-center text-base font-bold hover:bg-soil hover:text-cantelope 2xs:max-w-screen-2xs"
                  id="cta_button_call_us_now"
                >
                  {formatPhoneNumber(global.phone_pre_need)}
                </button>
              </a>

              <a
                href="mailto:support@after.com"
                className="block w-full rounded-lg bg-oat px-6 py-4 text-center text-base font-bold hover:bg-soil hover:text-oat 2xs:max-w-screen-2xs"
              >
                support@after.com
              </a>
            </div>
          </div>
        </div>
      )}

      {!showDetails && (
        <div className="rounded-lg border border-oat">
          <SectionHeader head={headerTitle} />
          <div className="flex flex-col divide-y divide-oat">
            {cases.map((caseItem) => (
              <div key={caseItem.planId}>
                <div className="flex flex-col gap-4">
                  {!showDetails && <Card {...caseItem} />}
                  {showDetails && (
                    <ProgressStepper
                      steps={caseItem.steps.map((step) => ({
                        ...step,
                        description: getStepDescription(step),
                      }))}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const FilesSection = ({ plans }: { plans: AccountState["plans"] }) => {
  if (!plans?.length) return null;

  const [uploadedFiles, setUploadedFiles] = useState<Record<string, boolean>>({
    powerOfAttorney: false,
    advanceDirective: true,
    trustWill: false,
  });

  const handleUpload = (fileId: string) => {
    setUploadedFiles((prev) => ({ ...prev, [fileId]: true }));
  };

  const handleView = (fileId: string) => {
    console.log(`Viewing ${fileId}`);
  };

  const handleReplace = (fileId: string) => {
    console.log(`Replacing ${fileId}`);
  };

  const fileData = [
    {
      id: "powerOfAttorney",
      title: "Power of Attorney",
      description: "Ensure your wishes are carried out",
      iconSrc: "public/bird.png",
      bgColor: "daisy",
    },
    {
      id: "advanceDirective",
      title: "Advance Directive",
      description: "Protect your assets and loved ones",
      iconSrc: "public/bird.png",
      bgColor: "cantelope",
    },
    {
      id: "trustWill",
      title: "Trust & Will",
      description: "Provide financial security",
      iconSrc: "public/bird.png",
      bgColor: "sage",
    },
  ];

  return (
    <>
      <div className="rounded-lg border border-oat">
        <SectionHeader head="Your files" />
        <div className="flex flex-col divide-y divide-oat">
          {fileData.map((file) => (
            <FileCard
              key={file.id}
              title={file.title}
              description={file.description}
              iconSrc={file.iconSrc}
              isUploaded={uploadedFiles[file.id]}
              onUpload={() => handleUpload(file.id)}
              onView={() => handleView(file.id)}
              onReplace={() => handleReplace(file.id)}
              bgColor={file.bgColor}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default function AccountPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { demoType } = useParams();
  const password = new URLSearchParams(location.search).get("pw");
  const { user } = useFirebase();
  const [message, setMessage] = useState<string | null>(null);

  // If we're on a demo route but password is wrong, redirect to regular account
  useEffect(() => {
    if (demoType && password !== DEMO_PASSWORD) {
      navigate("/account");
    }
  }, [demoType, password, navigate]);

  // Get demo state based on URL params
  let demoState = "prePlanOnly";

  if (password === DEMO_PASSWORD) {
    switch (demoType) {
      case "preplan":
        demoState = "prePlanOnly";
        break;
      case "care":
        demoState = "activeCase";
        break;
      case "convert":
        demoState = "convertedPlan";
        break;
      case "both":
        demoState = "bothActive";
        break;
      case "multiple":
        demoState = "multiplePreplans";
        break;
    }
  }

  // If we're still rendering and password is wrong, return null
  if (demoType && password !== DEMO_PASSWORD) return null;

  const currentState = demoStates[demoState];

  // Handle success message from location state
  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
      // Clear the message from history state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  // Determine what details to show based on the number of items
  const totalItems = currentState.plans.length + currentState.cases.length;
  const showPlanDetails = currentState.plans.length === 1 && totalItems === 1;
  const showCaseDetails = currentState.cases.length === 1 && totalItems === 1;

  return (
    <BasecampContainer>
      <Header logoLinksHome basecamp />

      <img
        src="public/bird.png"
        alt="Bird"
        className="absolute -right-8 top-20 h-24 opacity-50"
      />

      <BasecampContent>
        {message && <SuccessMessage message={message} />}

        <div className="relative z-10">
          <h1 className="text-center font-serif text-3xl italic text-soil sm:text-4xl">
            {showCaseDetails
              ? `We're here for you, ${currentState.name}`
              : `Welcome, ${currentState.name}`}
          </h1>
        </div>

        <PlansSection
          plans={currentState.plans}
          showDetails={showPlanDetails}
        />
        <CasesSection
          cases={currentState.cases}
          showDetails={showCaseDetails}
        />
      </BasecampContent>
      <Footer basecamp />
    </BasecampContainer>
  );
}
