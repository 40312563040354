import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  BasecampContainer,
  BasecampContent,
} from "layouts/OnboardingLayout/OnboardingLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import ProgressStepper, { ProgressStep } from "components/ProgressStepper";
import { demoStates } from "./demoStates";

const DEMO_PASSWORD = "after-basecamp-demo";

const SectionHeader = ({
  head,
  subhead = "",
}: {
  head: string;
  subhead?: string;
}) => (
  <div>
    <h2 className="py-4 text-lg font-semibold text-evergreen">{head}</h2>
    {subhead && (
      <h3 className="text-sm font-normal text-evergreen/60">{subhead}</h3>
    )}
  </div>
);

const PrePlanDetails = () => {
  return (
    <div className="rounded-lg border border-oat">
      <SectionHeader
        head="Pre-planning for Abigail"
        subhead="ID: 88GL-1433-9"
      />
    </div>
  );
};

const AtNeedDetails = () => {
  const getStepDescription = (step: ProgressStep) =>
    step.state === "inactive" ? (
      <>
        <p className="text-base text-soil">
          We'll guide you through this when the time comes.
        </p>
      </>
    ) : (
      step.description
    );

  return (
    <div className="">
      <SectionHeader head="John Adams is in our care" />
      <div className="flex flex-col">
        {demoStates.activeCase.cases.map((caseItem) => (
          <div key={caseItem.planId}>
            <div className="flex flex-col gap-4">
              <ProgressStepper
                steps={caseItem.steps.map((step) => ({
                  ...step,
                  description: getStepDescription(step),
                }))}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function DetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { demoType } = useParams();
  const password = new URLSearchParams(location.search).get("pw");

  // If we're on a demo route but password is wrong, redirect to regular account
  useEffect(() => {
    if (demoType && password !== DEMO_PASSWORD) {
      navigate("/account");
    }
  }, [demoType, password, navigate]);

  // Get demo state based on URL params
  let demoState = "prePlanOnly";

  if (password === DEMO_PASSWORD) {
    switch (demoType) {
      case "preplan":
        demoState = "prePlanOnly";
        break;
      case "case":
        demoState = "activeCase";
        break;
    }
  }

  return (
    <BasecampContainer>
      <Header logoLinksHome basecamp />
      <BasecampContent>
        {demoType === "preplan" && <PrePlanDetails />}
        {demoType === "case" && <AtNeedDetails />}
      </BasecampContent>
      <Footer basecamp />
    </BasecampContainer>
  );
}
