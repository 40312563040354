import { useEffect, useState } from "react";
import { useFirebase } from "firebase";

type VerificationStatus = {
  needsEmailVerification: boolean;
  needsPhoneVerification: boolean;
  email?: string | null;
  phoneNumber?: string | null;
};

export function useVerificationStatus() {
  const [status, setStatus] = useState<VerificationStatus>({
    needsEmailVerification: false,
    needsPhoneVerification: false,
  });
  const { user } = useFirebase();

  useEffect(() => {
    if (!user) return;

    setStatus({
      // Need email verification if email exists but isn't verified
      needsEmailVerification: !!(user.email && !user.emailVerified),
      // Need phone verification if we're using password auth but no phone
      needsPhoneVerification: !!(
        user.phoneNumber &&
        !user.providerData.some((p) => p.providerId === "phone")
      ),
      email: user.email,
      phoneNumber: user.phoneNumber,
    });
  }, [user]);

  return status;
}
