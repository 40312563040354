import { ArrowLeftIcon } from "@heroicons/react/24/solid";

type BackButtonProps = {
  onClick: () => void;
};

export default function BackButton({ onClick }: BackButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="mt-6 flex w-full items-center justify-center text-gray-500 hover:text-soil"
    >
      <ArrowLeftIcon className="mr-2 h-4 w-4" />
      Back
    </button>
  );
}
