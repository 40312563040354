import React from "react";

export interface ProgressStep {
  title: string;
  description: string | React.ReactNode;
  state: "complete" | "active" | "inactive";
  action?: React.ReactNode;
}

interface ProgressStepperProps {
  steps: ProgressStep[];
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({
  steps,
}: {
  steps: Array<{
    title: string;
    description: string;
    state: "complete" | "active" | "inactive";
    action?: React.ReactNode;
  }>;
}) => {
  return (
    <div className="flex flex-col">
      {steps.map((step, index) => {
        const isLast = index === steps.length - 1;

        // Color configuration based on state
        const circleColor = {
          complete: "bg-sage",
          active: "bg-cantelope",
          inactive: "bg-oat",
        }[step.state];

        const colors = {
          complete: {
            line: "outline-sage",
            text: "text-soil",
            opacity: "opacity-80",
            descOpacity: "opacity-80",
          },
          active: {
            line: "outline-cantelope",
            text: "text-soil",
            opacity: "",
            descOpacity: "",
          },
          inactive: {
            line: "outline-oat",
            text: "text-soil",
            opacity: "opacity-40",
            descOpacity: "opacity-60",
          },
        };

        const config = colors[step.state];

        return (
          <div
            key={index}
            data-active={step.state}
            className="relative flex items-start gap-3"
          >
            <div className="flex flex-col items-center">
              <div
                data-state={step.state}
                className={`h-5 w-5 rounded-full ${circleColor} relative z-10 flex items-center justify-center`}
              >
                {step.state === "complete" && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.5"
                      y="1.5"
                      width="17"
                      height="17"
                      rx="8.5"
                      fill="white"
                    />
                    <rect
                      x="1.5"
                      y="1.5"
                      width="17"
                      height="17"
                      rx="8.5"
                      stroke="#FFB885"
                      stroke-width="3"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM14.6337 8.38375C14.8614 8.148 14.9874 7.83224 14.9846 7.5045C14.9817 7.17675 14.8503 6.86324 14.6185 6.63148C14.3868 6.39972 14.0732 6.26826 13.7455 6.26541C13.4178 6.26256 13.102 6.38855 12.8663 6.61625L8.75 10.7325L7.13375 9.11625C6.898 8.88855 6.58224 8.76256 6.2545 8.76541C5.92675 8.76826 5.61324 8.89972 5.38148 9.13148C5.14972 9.36324 5.01826 9.67675 5.01541 10.0045C5.01256 10.3322 5.13855 10.648 5.36625 10.8837L7.86625 13.3837C8.10066 13.6181 8.41854 13.7497 8.75 13.7497C9.08146 13.7497 9.39934 13.6181 9.63375 13.3837L14.6337 8.38375Z"
                      fill="#91B89E"
                    />
                  </svg>
                )}
                {step.state === "active" && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="20" height="20" rx="10" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM14.6337 9.11625L10.8837 5.36625C10.648 5.13855 10.3322 5.01256 10.0045 5.01541C9.67675 5.01826 9.36324 5.14972 9.13148 5.38148C8.89972 5.61324 8.76826 5.92675 8.76541 6.2545C8.76256 6.58224 8.88855 6.898 9.11625 7.13375L10.7325 8.75H6.25C5.91848 8.75 5.60054 8.8817 5.36612 9.11612C5.1317 9.35054 5 9.66848 5 10C5 10.3315 5.1317 10.6495 5.36612 10.8839C5.60054 11.1183 5.91848 11.25 6.25 11.25H10.7325L9.11625 12.8663C8.99686 12.9816 8.90163 13.1195 8.83612 13.272C8.77061 13.4245 8.73613 13.5885 8.73469 13.7545C8.73324 13.9205 8.76487 14.0851 8.82772 14.2387C8.89057 14.3923 8.98339 14.5319 9.10076 14.6492C9.21812 14.7666 9.35769 14.8594 9.51131 14.9223C9.66493 14.9851 9.82953 15.0168 9.9955 15.0153C10.1615 15.0139 10.3255 14.9794 10.478 14.9139C10.6305 14.8484 10.7684 14.7531 10.8837 14.6337L14.6337 10.8837C14.8681 10.6493 14.9997 10.3315 14.9997 10C14.9997 9.66854 14.8681 9.35066 14.6337 9.11625Z"
                      fill="#FFB885"
                    />
                  </svg>
                )}
              </div>

              {!isLast && (
                <div className="absolute z-0 h-full flex-grow">
                  <div
                    className={`mx-auto mt-1 h-full w-[1px] ${config.line.replace("outline", "bg")}`}
                  ></div>
                </div>
              )}
            </div>

            <div
              className={`flex-2 flex w-full flex-col gap-2 pb-8 ${config.opacity}`}
            >
              <div className={`text-lg font-bold ${config.text} leading-tight`}>
                {step.title}
              </div>
              <div
                className={`text-base ${config.text} ${config.descOpacity} leading-normal`}
              >
                {step.description}
              </div>

              {step.state === "active" && <div className="">{step.action}</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressStepper;
