import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
} from "react-aria-components";
import type { MenuItemProps } from "react-aria-components";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useFirebase } from "firebase";
import { useNavigate } from "react-router-dom";

function ActionItem(props: MenuItemProps) {
  return (
    <MenuItem
      {...props}
      className="group box-border flex w-full cursor-pointer items-center rounded-md px-3 py-2 text-soil outline-none focus:bg-cantelope-200"
    />
  );
}

export default function UserNav() {
  const { user, loading, logout } = useFirebase();
  const navigate = useNavigate();

  // temp flag to force showing the menu in dev without user
  const forceShowMenu = false;

  // Change this condition to include the forceShowMenu flag
  if (loading || (!user && !forceShowMenu)) {
    return null;
  }

  async function handleLogout() {
    await logout();
  }

  return (
    <MenuTrigger>
      <Button
        aria-label="Menu"
        className="pressed:bg-opacity-40 pressed:bg-black inline-flex items-center justify-center rounded-md border border-white/20 p-0 text-soil outline-none transition-colors hover:bg-black hover:bg-opacity-20 focus-visible:bg-black focus-visible:bg-opacity-30 focus-visible:ring-2 focus-visible:ring-white/75"
      >
        <Bars3Icon className="h-8 w-8 text-soil" />
      </Button>
      <Popover className="entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 fill-mode-forwards w-56 origin-top-left overflow-auto rounded-md bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
        <Menu className="outline-none">
          <ActionItem onAction={() => navigate("/account")}>
            Account Settings
          </ActionItem>
          <ActionItem onAction={handleLogout}>Sign Out</ActionItem>
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
