import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useForm, FormProvider } from "react-hook-form";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useLocation } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getFriendlyErrorMessage } from "utils/errors";

import { useFirebase } from "firebase";
import { fetchPublicAPI, NetworkError } from "hooks/usePublicAPI";
import AuthLayout from "layouts/AuthLayout";
import PasswordVerification from "pages/Login/PasswordVerification";
import OTPVerification from "pages/Login/OTPVerification";
import BackButton from "components/Auth/BackButton";
import { useSMSVerification } from "hooks/useSMSVerification";
import { useEmailLinkAuth } from "hooks/useEmailLinkAuth";

const lookupSchema = z.object({
  identifier: z.string().min(1, "Please enter an email or phone number"),
});

type LookupData = z.infer<typeof lookupSchema>;

type LoginStep = "lookup" | "password" | "sms_otp" | "email_otp";
type LoginOption = {
  login_type: "PASSWORD" | "SMS_OTP" | "EMAIL_LINK";
  display_label?: string;
};

type LookupResponse = {
  options: LoginOption[];
};

function getOptionLabel(type: LoginOption["login_type"]) {
  switch (type) {
    case "PASSWORD":
      return "Enter password";
    case "SMS_OTP":
      return "Text a code";
    case "EMAIL_LINK":
      return "Email a sign in link";
  }
}

function getVerificationText(step: LoginStep) {
  switch (step) {
    case "password":
      return {
        title: "Enter Password",
        description: "Please enter your password to continue",
      };
    case "email_otp":
      return {
        title: "Check Your Email",
        description: "We sent you a sign in link",
      };
    case "sms_otp":
      return {
        title: "Enter Code",
        description: "Please enter the verification code",
      };
    default:
      return {
        title: "Enter Code",
        description: "Please enter the verification code",
      };
  }
}

export default function LoginPage() {
  const [step, setStep] = useState<LoginStep>("lookup");
  const [error, setError] = useState<string | null>(null);
  const [loginOptions, setLoginOptions] = useState<LoginOption[]>([]);
  const [identifier, setIdentifier] = useState("");
  const methods = useForm<LookupData>({
    resolver: zodResolver(lookupSchema),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, user, loading } = useFirebase();

  const {
    isVerifying: smsIsVerifying,
    error: smsError,
    clearError: clearSMSError,
    startVerification,
    verifyCode,
  } = useSMSVerification();

  const { isSending: emailIsSending, sendSignInLink } = useEmailLinkAuth();

  // Redirect if already logged in
  useEffect(() => {
    if (!loading && user) {
      const params = new URLSearchParams(location.search);
      const redirect = params.get("redirect") || "/";
      navigate(redirect, { replace: true });
    }
  }, [user, loading, navigate, location]);

  // Show loading state while checking auth
  if (loading) {
    return (
      <AuthLayout title="Loading..." description="">
        <div className="flex justify-center">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-cantelope border-t-transparent" />
        </div>
      </AuthLayout>
    );
  }

  // Don't render login form if we're logged in (prevents flash before redirect)
  if (user) {
    return null;
  }

  const handleLookup = async (data: LookupData) => {
    try {
      setError(null);
      setIdentifier(data.identifier);
      // Simple email regex to determine parameter type
      const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.identifier);
      const queryParam = isEmail
        ? `email=${encodeURIComponent(data.identifier)}`
        : `phone=${encodeURIComponent(data.identifier)}`;

      const responseData = await fetchPublicAPI<LookupResponse>(
        `/accounts/login/lookup?${queryParam}`,
      );
      setLoginOptions(responseData.options);
      setStep("lookup"); // Show options first instead of going straight to password
    } catch (err) {
      if (err instanceof NetworkError) {
        switch (err.statusCode) {
          case 400:
            setError("Please enter a valid email address or phone number");
            break;
          case 404:
            setError("No account found with that email or phone number.");
            break;
          case 429:
            setError("Too many attempts. Please try again later.");
            break;
          case 500:
            setError("Something went wrong. Please try again.");
            break;
          default:
            setError("An unexpected error occurred. Please try again.");
        }
      } else {
        Sentry.captureException(err);
        setError("An unexpected error occurred. Please try again.");
      }
      setLoginOptions([]);
    }
  };

  const verifyPassword = async (password: string) => {
    try {
      setError(null);
      await signInWithEmailAndPassword(auth, identifier, password);
    } catch (err: any) {
      setError(getFriendlyErrorMessage(err.code));
    }
  };

  const startSMSVerification = async () => {
    const success = await startVerification(identifier);
    if (success) {
      setStep("sms_otp");
    }
  };

  const verifyOTP = async (code: string, type: "sms" | "email") => {
    try {
      if (type === "sms") {
        await verifyCode(code);
      } else {
        // TODO: Implement email link signin
      }
    } catch (error: any) {
      console.log(error);
      setError(getFriendlyErrorMessage(error.code));
    }
  };

  const startEmailVerification = async () => {
    const success = await sendSignInLink(identifier);
    if (success) {
      setStep("email_otp");
    }
  };

  const handleBack = () => {
    setError(null);
    clearSMSError(); // Clear SMS error state
    if (step === "lookup") {
      setLoginOptions([]);
      methods.reset();
    } else {
      setStep("lookup");
      methods.reset();
    }
  };

  if (loginOptions.length > 0) {
    if (step === "lookup") {
      return (
        <AuthLayout
          title="Choose Sign In Method"
          description="Select how you'd like to sign in"
        >
          <div className="space-y-4">
            {loginOptions.map((option) => (
              <button
                key={option.login_type}
                onClick={() => {
                  if (option.login_type === "SMS_OTP") {
                    startSMSVerification();
                  } else if (option.login_type === "EMAIL_LINK") {
                    startEmailVerification();
                  } else {
                    setStep(option.login_type.toLowerCase() as LoginStep);
                  }
                }}
                disabled={smsIsVerifying || emailIsSending}
                className="flex w-full flex-col items-start rounded-lg border border-gray-200 p-4 text-left transition-colors hover:enabled:border-cantelope hover:enabled:bg-cantelope/5 disabled:cursor-not-allowed disabled:opacity-50"
              >
                <span className="text-lg font-semibold text-soil">
                  {(smsIsVerifying &&
                    option.login_type === "SMS_OTP" &&
                    "Sending code...") ||
                    (emailIsSending &&
                      option.login_type === "EMAIL_LINK" &&
                      "Sending link...") ||
                    getOptionLabel(option.login_type)}
                </span>
                {option.display_label && (
                  <span className="text-sm text-gray-500">
                    {option.display_label}
                  </span>
                )}
              </button>
            ))}
            <BackButton onClick={handleBack} />
            {smsError && (
              <div className="rounded bg-red-100 p-3 text-sm text-red-700">
                {smsError}
              </div>
            )}
          </div>
        </AuthLayout>
      );
    }

    let verificationComponent;

    switch (step) {
      case "password":
        verificationComponent = (
          <PasswordVerification onSubmit={verifyPassword} error={error} />
        );
        break;
      case "sms_otp":
        verificationComponent = (
          <OTPVerification
            type="sms"
            identifier={identifier}
            displayLabel={
              loginOptions.find((o) => o.login_type === "SMS_OTP")
                ?.display_label
            }
            onSubmit={(code) => verifyOTP(code, "sms")}
            onRequestNewCode={() => startSMSVerification()}
            error={error || smsError}
          />
        );
        break;
      case "email_otp":
        verificationComponent = (
          <OTPVerification
            type="email"
            identifier={identifier}
            displayLabel={
              loginOptions.find((o) => o.login_type === "EMAIL_LINK")
                ?.display_label
            }
            onSubmit={(code) => verifyOTP(code, "email")}
            error={error}
          />
        );
        break;
    }

    return (
      <AuthLayout
        title={getVerificationText(step).title}
        description={getVerificationText(step).description}
      >
        <div className="space-y-4">
          {verificationComponent}
          <BackButton onClick={handleBack} />
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      title="Welcome Back"
      description="Sign in to your After account"
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleLookup)}
          className="space-y-6"
        >
          <div>
            <input
              type="text"
              {...methods.register("identifier")}
              className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
              placeholder="Enter email or phone"
            />
            <div className="mt-1 text-sm text-gray-500">
              Standard SMS rates may apply
            </div>
            {methods.formState.errors.identifier && (
              <div className="mt-1 text-sm text-red-600">
                {methods.formState.errors.identifier.message}
              </div>
            )}
          </div>

          {error && (
            <div className="rounded bg-red-100 p-3 text-sm text-red-700">
              {error}
            </div>
          )}

          <button
            type="submit"
            className="flex w-full items-center justify-center rounded bg-cantelope px-6 py-3 font-bold text-soil transition-all hover:bg-soil hover:text-cantelope"
            disabled={methods.formState.isSubmitting}
          >
            <LockClosedIcon className="mr-2 h-5 w-5" />
            {methods.formState.isSubmitting ? "Checking..." : "Sign in"}
          </button>
        </form>
      </FormProvider>

      <div className="mt-8 border-t border-gray-200 pt-4 text-center text-xs text-gray-500">
        Invisible reCAPTCHA by Google{" "}
        <a
          href="https://www.google.com/intl/en/policies/privacy/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block underline decoration-dotted hover:decoration-solid"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://www.google.com/intl/en/policies/terms/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block underline decoration-dotted hover:decoration-solid"
        >
          Terms of Use
        </a>
      </div>
    </AuthLayout>
  );
}
