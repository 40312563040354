import React from "react";
import { Link } from "react-router-dom";

import LogoWithType from "svg/logo-with-type.svg";
import CallOrSchedule, {
  CallOrScheduleDetails,
} from "components/CallOrSchedule";
import UserNav from "./UserNav";
import { PhoneIcon } from "@heroicons/react/24/solid";

type HeaderProps = {
  properties?: {
    call_or_schedule?: CallOrScheduleDetails;
  };
  logoLinksHome?: boolean;
  basecamp?: boolean;
};

const Header: React.FC<HeaderProps> = ({
  properties,
  logoLinksHome = false,
  basecamp = false,
}) => {
  const Logo = (
    <div className="w-20 sm:w-28 md:w-32">
      <LogoWithType />
    </div>
  );

  if (basecamp) {
    return (
      <header className="items-center justify-center border-b border-oat bg-white">
        <div className="h-18 mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-start gap-2">
            <UserNav />
            <Link to="/">{Logo}</Link>
          </div>

          <div className="flex justify-end">
            <div className="flex items-center gap-2">
              <PhoneIcon className="h-4 w-4 text-plum" />
              <p className="text-sm font-bold text-plum">Need Help?</p>
            </div>
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className="flex h-16 items-center justify-center">
      <div className="flex w-full max-w-3xl grid-cols-2 items-center justify-between sm:grid sm:grid-cols-3">
        <div className="flex justify-start">
          <UserNav />
        </div>

        <div className="col-start-auto flex justify-center px-4 sm:col-start-2 sm:px-0">
          {logoLinksHome ? <Link to="/">{Logo}</Link> : Logo}
        </div>

        <div className="flex justify-end">
          {properties?.call_or_schedule && (
            <CallOrSchedule details={properties.call_or_schedule} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
