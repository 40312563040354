import { Outlet, Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";

import { sentryCreateBrowserRouter } from "sentry";
import { useFirebase } from "firebase";

import Loading from "components/Loading";
import LandingLayout from "layouts/LandingLayout";
import OnboardingLayout from "layouts/OnboardingLayout";

import ErrorPage from "pages/ErrorPage";
import Form from "pages/Form";
import QuoteOptions from "pages/QuoteOptions";
import QuotePayment from "pages/QuotePayment";
import QuoteFinalize from "pages/QuoteFinalize";
import OutOfMarket from "pages/OutOfMarket";
import Home from "pages/Home";
import LoginPage from "pages/Login";
import ResetPage from "pages/Reset";
import EmailActionPage from "pages/EmailAction";
import AccountPage from "pages/Account/Account";
import DetailsPage from "pages/Account/Details";

// TODO: Make this new home page
// If unauthenticated, redirect to login page
// If authenticated, show home page dashboard
const Root = () => {
  return <Outlet />;
};

function AuthRequired({ children }: { children: ReactNode }) {
  const { user, loading } = useFirebase();
  const location = useLocation();

  if (loading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loading message="Loading..." />
      </div>
    );
  }

  if (!user) {
    // Only add redirect param if we're not already going to the default path
    const redirectPath =
      location.pathname === "/"
        ? "/login"
        : `/login?redirect=${encodeURIComponent(location.pathname)}`;
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
}

const SecretTestPage = () => (
  <div className="p-8">
    <h1 className="mb-4 text-2xl font-bold">Secret Page</h1>
    <p>If you can see this, you're authenticated! 🎉</p>
  </div>
);

export default sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <LandingLayout>
        <ErrorPage />
      </LandingLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <AuthRequired>
            <OnboardingLayout>
              <Home />
            </OnboardingLayout>
          </AuthRequired>
        ),
      },
      {
        path: "forms/:formId/:responseId",
        element: <Form />,
      },
      {
        path: "continue",
        element: (
          <OnboardingLayout>
            <Outlet />
          </OnboardingLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/" />,
          },
          {
            path: "pre-planning",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
              {
                path: "quote",
                element: <QuoteOptions />,
              },
              {
                path: "payment",
                element: <QuotePayment />,
              },
              {
                path: "finalize",
                element: <QuoteFinalize />,
              },
              {
                path: "out-of-market",
                element: <OutOfMarket />,
              },
            ],
          },
        ],
      },

      {
        path: "policies",
        element: (
          <OnboardingLayout>
            <Outlet />
          </OnboardingLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/" />,
          },
          // TODO: Policy detail page
          {
            path: ":policyId",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <Navigate to="/" />,
              },
            ],
          },
        ],
      },

      {
        path: "email-action",
        element: <EmailActionPage />,
      },

      {
        path: "login",
        element: <LoginPage />,
      },

      {
        path: "reset",
        element: <ResetPage />,
      },

      {
        path: "secret",
        element: (
          <AuthRequired>
            <SecretTestPage />
          </AuthRequired>
        ),
      },

      {
        path: "account",
        element: (
          <AuthRequired>
            <AccountPage />
          </AuthRequired>
        ),
      },
      {
        path: "account/demo/:demoType",
        element: <AccountPage />,
      },
      {
        path: "account/demo/details/:demoType",
        element: <DetailsPage />,
      },
    ],
  },
]);
