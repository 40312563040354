import React from "react";
import { ProgressStep } from "components/ProgressStepper";
import { formatPhoneNumber } from "helpers/phone";

const global = { phone_pre_need: "+18444150199" };

export type AccountState = {
  name: string;
  plans: Array<{
    name: string;
    planId: string;
    link: string;
    status: "green" | "yellow" | "red" | "blue";
    badgeText: string;
    type: "plan" | "case";
  }>;
  cases: Array<{
    name: string;
    planId: string;
    link: string;
    status: "green" | "yellow" | "red" | "blue";
    badgeText: string;
    type: "case";
    steps: ProgressStep[];
  }>;
};

export const demoStates: Record<string, AccountState> = {
  // 1. Pre-plan, No active cases
  prePlanOnly: {
    name: "Abigail",
    plans: [
      {
        name: "Abigail",
        planId: "88GL-1433-9",
        link: "/plan-details",
        status: "yellow",
        badgeText: "Pending",
        type: "plan",
        badgeCaption: "Your plan has been submitted and is pending review.",
      },
    ],
    cases: [],
  },

  multiplePreplans: {
    name: "Abigail",
    plans: [
      {
        name: "Abigail",
        planId: "88GL-1433-9",
        link: "/plan-details",
        status: "yellow",
        badgeText: "Pending",
        type: "plan",
      },
      {
        name: "John",
        planId: "2344-OPJL-9",
        link: "/plan-details",
        status: "green",
        badgeText: "Active",
        type: "plan",
      },
    ],
    cases: [],
  },

  // 2. No pre-plan, One active case in our care
  activeCase: {
    name: "Abigail",
    plans: [],
    cases: [
      {
        name: "John",
        planId: "99DD-1009-1",
        link: "/case-details",
        status: "green",
        badgeText: "Active",
        type: "case",
        steps: [
          {
            title: "Next of Kin",
            description: (
              <div className="flex flex-col gap-2">
                <div>We show the next of kin for John as:</div>
                <div>
                  Brian D. Rhea
                  <br />
                  Boulder, CO, 80301
                </div>
                <div>
                  If this needs to be updated, please call or text us at{" "}
                  {formatPhoneNumber(global.phone_pre_need)}, and we'll get this
                  updated for you.
                </div>
              </div>
            ),
            state: "complete",
          },
          {
            title: "Complete Vitals Forms",
            description: (
              <>
                <div>
                  This form allows us to handle required permits and provide
                  death certificates on your behalf.
                </div>
              </>
            ),
            state: "active",
            action: (
              <button className="w-full rounded-lg bg-cantelope px-10 py-4 text-sm font-bold text-soil hover:bg-soil hover:text-cantelope sm:inline-block sm:w-auto">
                Complete Vitals Forms
              </button>
            ),
          },
          {
            title: "Urn Selection",
            description: "Select the urn for your loved one.",
            state: "inactive",
            action: (
              <button className="w-full rounded-lg bg-cantelope px-10 py-4 text-sm font-bold text-soil hover:bg-soil hover:text-cantelope sm:inline-block sm:w-auto">
                Select Urn
              </button>
            ),
          },
          {
            title: "Cremation Scheduled",
            description: "We have scheduled the cremation service.",
            state: "inactive",
          },
          {
            title: "Cremation Complete",
            description: "The cremation service has been completed.",
            state: "inactive",
          },
          {
            title: "Delivery Scheduled",
            description:
              "We have scheduled the delivery of your loved one's remains.",
            state: "inactive",
          },
          {
            title: "In Transit",
            description:
              "Your loved one's remains are being transported to the specified location.",
            state: "inactive",
          },
          {
            title: "Delivered",
            description:
              "Your loved one's remains have been delivered to the specified location.",
            state: "inactive",
          },
        ],
      },
    ],
  },

  // 3. A pre-plan policy that has converted to active in our care
  convertedPlan: {
    name: "Abigail",
    plans: [],
    cases: [
      {
        name: "Abigail",
        planId: "88GL-1433-9",
        link: "/case-details",
        status: "green",
        badgeText: "Active",
        type: "case",
        steps: [
          {
            title: "Next of Kin Notified",
            description:
              "Family has been contacted and arrangements are being made.",
            state: "complete",
          },
          {
            title: "Review Pre-Plan Details",
            description: "Confirm your pre-planned arrangements.",
            state: "active",
            action: (
              <button className="w-full rounded-lg bg-cantelope px-10 py-4 text-sm font-bold text-soil hover:bg-soil hover:text-cantelope sm:inline-block sm:w-auto">
                Review Details
              </button>
            ),
          },
          {
            title: "Urn Selection",
            description: "Select the urn for your loved one.",
            state: "inactive",
          },
          {
            title: "Cremation Scheduled",
            description: "We have scheduled the cremation service.",
            state: "inactive",
          },
          {
            title: "Cremation Complete",
            description: "The cremation service has been completed.",
            state: "inactive",
          },
          {
            title: "Delivery Scheduled",
            description:
              "We have scheduled the delivery of your loved one's remains.",
            state: "inactive",
          },
          {
            title: "In Transit",
            description:
              "Your loved one's remains are being transported to the specified location.",
            state: "inactive",
          },
          {
            title: "Delivered",
            description:
              "Your loved one's remains have been delivered to the specified location.",
            state: "inactive",
          },
        ],
      },
    ],
  },

  // 4. Pre-plan policy, and a separate active case in our care
  bothActive: {
    name: "Abigail",
    plans: [
      {
        name: "Abigail",
        planId: "88GL-1433-9",
        link: "/plan-details",
        status: "yellow",
        badgeText: "Pending",
        type: "plan",
      },
    ],
    cases: [
      {
        name: "John Adams",
        planId: "99DD-1009-1",
        link: "/case-details",
        badgeText: "Active",
        type: "case",
        steps: [
          {
            title: "Next of Kin",
            description:
              "Family has been contacted and arrangements are being made.",
            state: "complete",
          },
          {
            title: "Complete Vitals Forms",
            description:
              "We need your loved one's key details to order permits and death certificate.",
            state: "active",
            action: (
              <button className="w-full rounded-lg bg-cantelope px-10 py-4 text-sm font-bold text-soil hover:bg-soil hover:text-cantelope sm:inline-block sm:w-auto">
                Complete Vitals Forms
              </button>
            ),
          },
          {
            title: "Urn Selection",
            description: "Select the urn for your loved one.",
            state: "inactive",
          },
          {
            title: "Cremation Scheduled",
            description: "We have scheduled the cremation service.",
            state: "inactive",
          },
          {
            title: "Cremation Complete",
            description: "The cremation service has been completed.",
            state: "inactive",
          },
          {
            title: "Delivery Scheduled",
            description:
              "We have scheduled the delivery of your loved one's remains.",
            state: "inactive",
          },
          {
            title: "In Transit",
            description:
              "Your loved one's remains are being transported to the specified location.",
            state: "inactive",
          },
          {
            title: "Delivered",
            description:
              "Your loved one's remains have been delivered to the specified location.",
            state: "inactive",
          },
        ],
      },
    ],
  },
};
